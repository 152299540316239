import React, { useState, useEffect } from "react";
import { Roles } from "../../constants/roles";

const UserPermissionModal = ({
  onClose,
  selectedCase,
  userRole,
  onRoleChange,
}) => {
  const roleOrder = [
    Roles.ADMIN,
    Roles.CREATOR,
    Roles.OWNER,
    Roles.EDITOR,
    Roles.VIEWER,
  ];

  const [localUsers, setLocalUsers] = useState({});

  useEffect(() => {
    setLocalUsers({ ...selectedCase?.users });
  }, [selectedCase]);

  const sortedUsers = Object.entries(localUsers).sort(
    ([, userInfoA], [, userInfoB]) =>
      roleOrder.indexOf(userInfoA.role) - roleOrder.indexOf(userInfoB.role)
  );

  const canModifyRole = (targetRole) => {
    if (userRole === Roles.ADMIN || userRole === Roles.CREATOR) {
      return [Roles.OWNER, Roles.EDITOR, Roles.VIEWER].includes(targetRole);
    }
    if (userRole === Roles.OWNER) {
      return [Roles.EDITOR, Roles.VIEWER].includes(targetRole);
    }
    return false;
  };

  const getEditableRoles = () => {
    if (userRole === Roles.ADMIN || userRole === Roles.CREATOR) {
      return [Roles.OWNER, Roles.EDITOR, Roles.VIEWER];
    }
    if (userRole === Roles.OWNER) {
      return [Roles.EDITOR, Roles.VIEWER];
    }
    return [];
  };

  const editableRoles = getEditableRoles();

  const handleRoleChange = (userId, newRole) => {
    setLocalUsers((prevUsers) => ({
      ...prevUsers,
      [userId]: {
        ...prevUsers[userId],
        role: newRole,
      },
    }));
  };

  const handleSaveChanges = () => {
    if (onRoleChange) {
      onRoleChange(localUsers);
    }
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-sm">
        <h2 className="text-xl font-semibold mb-4">User Permissions</h2>
        <div className="space-y-2">
          {sortedUsers.map(([userId, userInfo]) => (
            <div key={userId} className="flex justify-between items-center">
              <span className="font-medium">
                {userInfo.name || "Unknown User"}
              </span>
              {canModifyRole(userInfo.role) ? (
                <select
                  className="mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm text-gray-700 p-1"
                  style={{ minWidth: "100px", maxWidth: "200px" }}
                  value={userInfo.role}
                  onChange={(e) => handleRoleChange(userId, e.target.value)}
                >
                  {editableRoles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              ) : (
                <span className="text-gray-500">{userInfo.role}</span>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4 space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition focus:outline-none"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveChanges}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition focus:outline-none"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserPermissionModal;
