import { useState } from "react";

function AnnotationModal({ isOpen, onSave, onCancel, initialPosition }) {
  const [text, setText] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSave = () => {
    if (!text.trim()) {
      setIsError(true);
      return;
    }
    onSave(text);
    setText("");
    setIsError(false);
  };

  const handleCancel = () => {
    onCancel();
    setText("");
    setIsError(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-semibold mb-4">Add Annotation</h2>
        <textarea
          className={`w-full p-2 border ${
            isError ? "border-red-500" : "border-gray-300"
          } rounded focus:outline-none focus:ring-2 ${
            isError ? "focus:ring-red-500" : "focus:ring-blue-500"
          }`}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            if (isError) setIsError(false);
          }}
          placeholder="Enter your annotation here..."
          rows="4"
        ></textarea>
        {isError && (
          <p className="text-red-500 text-sm mt-2">
            Please enter a text for the annotation.
          </p>
        )}{" "}
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={handleCancel}
            className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default AnnotationModal;
