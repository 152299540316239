import { useState, useEffect, useCallback } from "react";
import * as THREE from "three";
import { ref, push, update, remove, child } from "firebase/database";
import { useDispatch } from "react-redux";
import { updateMeasurements } from "../redux/casesRedux";
import { database } from "../firebase";

const useMeasurement = (
  sceneRef,
  cameraRef,
  modelRef,
  containerRef,
  caseId,
  initialMeasurements,
  undoStack,
  undoIndex,
  undoMeasurementStack
) => {
  const [pathPoints, setPathPoints] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [savedMeasurements, setSavedMeasurements] = useState(
    initialMeasurements || []
  );
  const [isDistanceMeasuringMode, setIsDistanceMeasuringMode] = useState(false);
  const [isMeasurementModalOpen, setIsMeasurementModalOpen] = useState(false);
  const dispatch = useDispatch();

  const calculateAndShowDistance = useCallback(
    (points) => {
      let total = 0;
      for (let i = 0; i < points.length - 1; i++) {
        const pointA = points[i].position;
        const pointB = points[i + 1].position;
        total += pointA.distanceTo(pointB);
      }
      setTotalDistance(total.toFixed(2));
    },
    [setTotalDistance]
  );

  const addPoint = useCallback(
    (event) => {
      const rect = containerRef.current.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera({ x, y }, cameraRef.current);

      const visibleModels = modelRef.current.children.filter(
        (child) => child.visible
      );

      if (visibleModels.length > 0) {
        const intersects = raycaster.intersectObjects(visibleModels, true);

        if (intersects.length > 0) {
          const intersectPoint = intersects[0].point;

          const geometry = new THREE.SphereGeometry(0.5, 32, 32);
          const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
          const sphere = new THREE.Mesh(geometry, material);
          sphere.position.copy(intersectPoint);
          sceneRef.current.add(sphere);

          if (!sceneRef.current.userData.tempPoints) {
            sceneRef.current.userData.tempPoints = [];
          }
          sceneRef.current.userData.tempPoints.push(sphere);

          setPathPoints((prevPoints) => {
            const updatedPoints = [...prevPoints, sphere];
            calculateAndShowDistance(updatedPoints);

            if (updatedPoints.length > 1) {
              const start = updatedPoints[updatedPoints.length - 2].position;
              const end = updatedPoints[updatedPoints.length - 1].position;

              const direction = new THREE.Vector3().subVectors(end, start);
              const distance = direction.length();
              const midpoint = new THREE.Vector3()
                .addVectors(start, end)
                .multiplyScalar(0.5);

              const lineGeometry = new THREE.CylinderGeometry(
                0.2,
                0.2,
                distance,
                32
              );
              const lineMaterial = new THREE.MeshBasicMaterial({
                color: 0xff0000,
              });
              const line = new THREE.Mesh(lineGeometry, lineMaterial);

              line.position.copy(midpoint);
              line.lookAt(end);
              line.rotateX(Math.PI / 2);

              sceneRef.current.add(line);

              if (!sceneRef.current.userData.tempLines) {
                sceneRef.current.userData.tempLines = [];
              }
              sceneRef.current.userData.tempLines.push(line);
            }

            return updatedPoints;
          });
        }
      }
    },
    [cameraRef, modelRef, sceneRef, calculateAndShowDistance, containerRef]
  );

  const closePolygon = useCallback(() => {
    if (pathPoints.length < 3) return;

    setPathPoints((prevPoints) => {
      if (prevPoints.length > 2) {
        const lastPoint = prevPoints[prevPoints.length - 1].position;
        const firstPoint = prevPoints[0].position;

        if (lastPoint.equals(firstPoint)) {
          return prevPoints;
        }
      }

      const newPoints = [...prevPoints];
      const firstPointClone = prevPoints[0].clone();
      newPoints.push(firstPointClone);

      for (let i = 1; i < newPoints.length; i++) {
        const pointA = newPoints[i - 1].position;
        const pointB = newPoints[i].position;

        const direction = new THREE.Vector3().subVectors(pointB, pointA);
        const distance = direction.length();
        const midpoint = new THREE.Vector3()
          .addVectors(pointA, pointB)
          .multiplyScalar(0.5);

        const lineGeometry = new THREE.CylinderGeometry(0.2, 0.2, distance, 32);
        const lineMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        const line = new THREE.Mesh(lineGeometry, lineMaterial);

        line.position.copy(midpoint);
        line.lookAt(pointB);
        line.rotateX(Math.PI / 2);

        if (!sceneRef.current.userData.tempLines) {
          sceneRef.current.userData.tempLines = [];
        }
        sceneRef.current.add(line);
        sceneRef.current.userData.tempLines.push(line);
      }

      let totalDistance = 0;
      for (let i = 1; i < newPoints.length; i++) {
        const pointA = newPoints[i - 1].position;
        const pointB = newPoints[i].position;
        totalDistance += pointA.distanceTo(pointB);
      }

      setTotalDistance(totalDistance.toFixed(2));
      return newPoints;
    });
  }, [pathPoints, sceneRef, setPathPoints, setTotalDistance]);

  const clearMeasurements = useCallback(() => {
    if (!sceneRef.current || !sceneRef.current.userData) {
      console.warn("Scene or userData is not initialized.");
      return;
    }

    // Clear the temporary points
    if (sceneRef.current.userData.tempPoints) {
      sceneRef.current.userData.tempPoints.forEach((sphere) => {
        if (sphere) {
          sceneRef.current.remove(sphere);

          // Dispose geometry and material only if they exist
          if (sphere.geometry) {
            sphere.geometry.dispose();
          }
          if (sphere.material) {
            sphere.material.dispose();
          }
        }
      });

      // Reset the temporary points array
      sceneRef.current.userData.tempPoints = [];
    }

    // Clear the temporary lines
    if (sceneRef.current.userData.tempLines) {
      sceneRef.current.userData.tempLines.forEach((line) => {
        if (line) {
          sceneRef.current.remove(line);

          // Dispose geometry and material only if they exist
          if (line.geometry) {
            line.geometry.dispose();
          }
          if (line.material) {
            line.material.dispose();
          }
        }
      });

      // Reset the temporary lines array
      sceneRef.current.userData.tempLines = [];
    }

    // Reset pathPoints and total distance
    setPathPoints([]);
    setTotalDistance(0);
  }, [sceneRef, setPathPoints, setTotalDistance]);

  const renderSavedMeasurement = useCallback(
    (measurement) => {
      if (!sceneRef.current) {
        console.warn("Scene is not ready yet.");
        return;
      }

      const points = [];

      // Render points
      measurement.points.forEach((pointData) => {
        const geometry = new THREE.SphereGeometry(0.5, 32, 32);
        const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
        const sphere = new THREE.Mesh(geometry, material);
        sphere.position.set(
          pointData.position.x,
          pointData.position.y,
          pointData.position.z
        );
        sceneRef.current.add(sphere);
        points.push(sphere);

        // Track the point in userData
        if (!sceneRef.current.userData.points) {
          sceneRef.current.userData.points = [];
        }
        sceneRef.current.userData.points.push(sphere);
      });

      // Render lines between points
      for (let i = 0; i < points.length - 1; i++) {
        const start = points[i].position;
        const end = points[i + 1].position;

        const direction = new THREE.Vector3().subVectors(end, start);
        const distance = direction.length();
        const midpoint = new THREE.Vector3()
          .addVectors(start, end)
          .multiplyScalar(0.5);

        const lineGeometry = new THREE.CylinderGeometry(0.2, 0.2, distance, 32);
        const lineMaterial = new THREE.MeshBasicMaterial({
          color: 0xff0000,
        });
        const line = new THREE.Mesh(lineGeometry, lineMaterial);

        line.position.copy(midpoint);
        line.lookAt(end);
        line.rotateX(Math.PI / 2);

        sceneRef.current.add(line);

        // Track the line in userData
        if (!sceneRef.current.userData.lines) {
          sceneRef.current.userData.lines = [];
        }
        sceneRef.current.userData.lines.push(line);
      }
    },
    [sceneRef]
  );

  const clearSavedMeasurement = useCallback(
    (measurement) => {
      if (!sceneRef.current || !sceneRef.current.userData) return;

      // Remove points
      if (sceneRef.current.userData.points) {
        sceneRef.current.userData.points =
          sceneRef.current.userData.points.filter((point) => {
            const pointPosition = point.position;
            const isPartOfMeasurement = measurement.points.some(
              (pointData) =>
                pointData.position.x === pointPosition.x &&
                pointData.position.y === pointPosition.y &&
                pointData.position.z === pointPosition.z
            );
            if (isPartOfMeasurement) {
              sceneRef.current.remove(point); // Remove from scene
              return false; // Exclude from userData.points
            }
            return true; // Keep in userData.points
          });
      }

      // Remove lines
      if (sceneRef.current.userData.lines) {
        sceneRef.current.userData.lines =
          sceneRef.current.userData.lines.filter((line) => {
            const isPartOfMeasurement = measurement.points.some(
              (pointData, index) => {
                if (index === measurement.points.length - 1) return false;
                const start = new THREE.Vector3(
                  pointData.position.x,
                  pointData.position.y,
                  pointData.position.z
                );
                const end = new THREE.Vector3(
                  measurement.points[index + 1].position.x,
                  measurement.points[index + 1].position.y,
                  measurement.points[index + 1].position.z
                );
                const lineMidpoint = new THREE.Vector3()
                  .addVectors(start, end)
                  .multiplyScalar(0.5);
                return line.position.equals(lineMidpoint);
              }
            );
            if (isPartOfMeasurement) {
              sceneRef.current.remove(line); // Remove from scene
              return false; // Exclude from userData.lines
            }
            return true; // Keep in userData.lines
          });
      }
    },
    [sceneRef]
  );

  const handleSaveMeasurements = useCallback(
    (name, measurementDataToResave = null, skipUndoStack = true) => {
      // Resave mode: if measurementDataToResave is provided, restore that measurement.
      if (measurementDataToResave) {
        update(
          ref(
            database,
            `cases/${caseId}/items/measurements/${measurementDataToResave.id}`
          ),
          measurementDataToResave
        )
          .then(() => {
            // Update local state with the restored measurement.
            setSavedMeasurements((prev) => [
              ...prev,
              { ...measurementDataToResave, show: true },
            ]);
            // Render the measurement on the scene.
            renderSavedMeasurement(measurementDataToResave);
            // Dispatch Redux update.
            dispatch(
              updateMeasurements({
                actionType: "add",
                measurementId: measurementDataToResave.id,
                measurementData: measurementDataToResave,
              })
            );
          })
          .catch((err) => console.error("Error resaving measurement:", err));
      } else {
        // Normal save mode: create a new measurement using the current pathPoints.
        if (pathPoints.length === 0) {
          console.warn("No points to save!");
          return;
        }

        // Build the points data from the current pathPoints.
        const pointsData = pathPoints.map((point, index) => ({
          id: index + 1,
          position: {
            x: point.position.x,
            y: point.position.y,
            z: point.position.z,
          },
        }));

        const pathData = {
          name,
          points: pointsData,
          totalDistance,
          show: true,
        };

        console.log("Saving path data:", pathData);
        const newMeasurementId = push(
          child(ref(database), `cases/${caseId}/items/measurements`)
        ).key;

        update(
          ref(
            database,
            `cases/${caseId}/items/measurements/${newMeasurementId}`
          ),
          pathData
        )
          .then(() => {
            // Clear temporary measurement artifacts from the scene.
            clearMeasurements();
            const savedMeasurement = { ...pathData, id: newMeasurementId };
            // Update local state.
            setSavedMeasurements((prevMeasurements) => [
              ...prevMeasurements,
              savedMeasurement,
            ]);
            // Render the measurement.
            renderSavedMeasurement(savedMeasurement);
            // Dispatch Redux update.
            dispatch(
              updateMeasurements({
                actionType: "add",
                measurementId: newMeasurementId,
                measurementData: savedMeasurement,
              })
            );
            // Push undo action if required.
            if (skipUndoStack) {
              const measurementUndo = {
                type: "measurement",
                measurementId: newMeasurementId,
                measurementData: savedMeasurement,
                action: "save",
              };
              // Remove any future redo actions.
              undoStack.current = undoStack.current.slice(0, undoIndex.current);
              undoStack.current.push(measurementUndo);
              undoIndex.current += 1;
            }
          })
          .catch((err) => console.error("Error saving measurement:", err));
      }
      // Close the measurement modal regardless of the mode.
      setIsMeasurementModalOpen(false);
    },
    [
      caseId,
      clearMeasurements,
      dispatch,
      pathPoints,
      renderSavedMeasurement,
      setIsMeasurementModalOpen,
      totalDistance,
      undoStack,
      undoIndex,
    ]
  );

  const handleDeleteMeasurement = useCallback(
    (index, skipUndoStack = true) => {
      const measurementToDelete = savedMeasurements[index];

      if (!measurementToDelete) return;

      if (measurementToDelete.show) {
        clearSavedMeasurement(measurementToDelete);
      }

      const updatedMeasurements = savedMeasurements.filter(
        (_, i) => i !== index
      );
      setSavedMeasurements(updatedMeasurements);

      remove(
        ref(
          database,
          `cases/${caseId}/items/measurements/${measurementToDelete.id}`
        )
      );

      dispatch(
        updateMeasurements({
          actionType: "delete",
          measurementId: measurementToDelete.id,
        })
      );

      if (skipUndoStack) {
        const deleteUndo = {
          type: "measurement",
          measurementId: measurementToDelete.id,
          measurementData: measurementToDelete,
          action: "delete",
        };

        undoStack.current = undoStack.current.slice(0, undoIndex.current);
        undoStack.current.push(deleteUndo);
        undoIndex.current += 1;
      }
    },
    [
      caseId,
      dispatch,
      savedMeasurements,
      clearSavedMeasurement,
      undoStack,
      undoIndex,
    ]
  );

  const handleSaveMeasurementText = useCallback(
    (measurementText, measurementId) => {
      update(
        ref(database, `cases/${caseId}/items/measurements/${measurementId}`),
        {
          name: measurementText,
        }
      );

      setSavedMeasurements((prevMeasurements) =>
        prevMeasurements.map((measurement) =>
          measurement.id === measurementId
            ? { ...measurement, name: measurementText }
            : measurement
        )
      );

      dispatch(
        updateMeasurements({
          actionType: "update",
          measurementId,
          measurementData: { name: measurementText },
        })
      );
    },
    [caseId, dispatch]
  );

  const handleMeasurementShowAndHide = useCallback(
    (checked, id) => {
      setSavedMeasurements((prevMeasurements) => {
        const updatedMeasurements = prevMeasurements.map((measurement) => {
          if (measurement.id === id) {
            if (checked) {
              // Render the measurement if visibility is toggled on
              renderSavedMeasurement(measurement);
            } else {
              // Clear the measurement if visibility is toggled off
              clearSavedMeasurement(measurement);
            }
            return { ...measurement, show: checked }; // Update visibility state
          }
          return measurement; // No changes for other measurements
        });
        return updatedMeasurements;
      });
    },
    [renderSavedMeasurement, clearSavedMeasurement]
  );

  useEffect(() => {
    let clickStartPos = { x: null, y: null };

    const onMouseDown = (event) => {
      if (event.button !== 0) return;
      clickStartPos = { x: event.clientX, y: event.clientY };
    };

    const onMouseUp = (event) => {
      if (event.button !== 0) return;
      const clickEndPos = { x: event.clientX, y: event.clientY };
      const dx = clickEndPos.x - clickStartPos.x;
      const dy = clickEndPos.y - clickStartPos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      // Check if the click is on a UI element
      if (event.target.closest(".ui-outside-scene")) {
        return;
      }

      if (distance < 5 && isDistanceMeasuringMode) {
        addPoint(event);
      }
    };

    // Attach event listeners
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [addPoint, isDistanceMeasuringMode]);

  return {
    pathPoints,
    totalDistance,
    savedMeasurements,
    isDistanceMeasuringMode,
    setIsDistanceMeasuringMode,
    closePolygon,
    clearMeasurements,
    handleSaveMeasurements,
    handleDeleteMeasurement,
    handleSaveMeasurementText,
    handleMeasurementShowAndHide,
    setIsMeasurementModalOpen,
    isMeasurementModalOpen,
  };
};

export default useMeasurement;
