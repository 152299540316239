import { TrashIcon } from "@heroicons/react/solid";
import { Statuses } from "../../constants/statuses";
import { useDispatch } from "react-redux";
import { setSelectedCase } from "../../redux/casesRedux";
import { Roles } from "../../constants/roles";

const CaseTable = ({
  caseList,
  requestSort,
  getSortDirection,
  showDeleteButtons,
  openHardDeleteModal,
  openSoftDeleteModal,
  navigate,
  user,
}) => {
  const dispatch = useDispatch();

  const handleRowClick = (caseItem) => {
    dispatch(setSelectedCase(caseItem));
    navigate(`/case/${caseItem.id}`);
  };

  const isCreator = (caseItem) => {
    return Object.entries(caseItem.users || {}).some(
      ([userId, userInfo]) =>
        userId === user?.id && userInfo.role === Roles.CREATOR
    );
  };

  return (
    <table className="w-full border-collapse">
      <thead>
        <tr className="bg-gray-200">
          {[
            { key: "title", label: "Title" },
            { key: "anatomy", label: "Anatomy" },
            { key: "creator", label: "Creator" },
            { key: "requester", label: "Requester" },
            { key: "shared", label: "Shared Users" },
            { key: "creationDate", label: "Creation Date" },
            { key: "dueDate", label: "Due Date" },
            { key: "expirationDate", label: "Expiration Date" },
            { key: "status", label: "Status" },
          ].map(({ key, label }) => (
            <th
              key={key}
              className="p-2 text-center border-l-2 border-l-white cursor-pointer"
              onClick={() => requestSort(key)}
            >
              {label} {getSortDirection(key)}
            </th>
          ))}
          {showDeleteButtons && (
            <th className="p-2 text-center border-l-2 border-l-white">
              Actions
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {caseList.map((caseItem, index) => (
          <tr
            key={index}
            className={`${
              index % 2 === 0 ? "bg-gray-100" : "bg-white"
            } cursor-pointer`}
            onClick={() => handleRowClick(caseItem)}
          >
            <td className="p-2 text-center break-words max-w-[200px]">
              {caseItem.title}
            </td>
            <td className="p-2 text-center break-words max-w-[200px]">
              {caseItem.anatomy}
            </td>
            <td className="p-2 text-center break-words max-w-[200px]">
              {caseItem.creator}
            </td>
            <td className="p-2 text-center break-words max-w-[200px]">
              {caseItem.requester}
            </td>
            <td className="p-2 text-center break-words max-w-[200px]">
              {Object.values(caseItem.users)
                .filter((user) => user.role !== Roles.CREATOR)
                .map((user) => user.name)
                .join(", ")}
            </td>
            <td className="p-2 text-center break-words max-w-[200px]">
              {caseItem.creationDate}
            </td>
            <td className="p-2 text-center break-words max-w-[200px]">
              {caseItem.dueDate}
            </td>
            <td className="p-2 text-center break-words max-w-[200px]">
              {caseItem.expirationDate}
            </td>
            <td
              className={`p-2 text-center break-words max-w-[200px] 
                ${
                  caseItem.status === Statuses.IN_PROGRESS
                    ? "bg-yellow-200"
                    : ""
                } 
                ${caseItem.status === Statuses.READY ? "bg-green-200" : ""} 
                ${
                  caseItem.status === Statuses.UPLOAD_DICOM
                    ? "bg-orange-200"
                    : ""
                }`}
            >
              {caseItem.status}
            </td>
            {showDeleteButtons && (
              <td
                className="p-2 text-center align-middle"
                onClick={(e) => {
                  e.stopPropagation();
                  if (isCreator(caseItem)) {
                    openHardDeleteModal(caseItem.id);
                  } else {
                    openSoftDeleteModal(caseItem.id);
                  }
                }}
              >
                <TrashIcon
                  className={`h-5 w-5 mx-auto ${
                    isCreator(caseItem)
                      ? "text-red-500 hover:text-red-600"
                      : "text-green-500 hover:text-green-600"
                  }`}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CaseTable;
