import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CameraOrientationModal = ({
  resetCamera,
  setHomeCameraView,
  goHomeCameraView,
  isHomeViewSet,
  isCameraRotationLocked,
  toggleCameraRotationLock,
  goTopView,
  goBottomView,
  goLeftView,
  goRightView,
  goFrontView,
  goBackView,
}) => {
  return (
    <div className="absolute bottom-12 left-4 w-auto flex flex-col items-center z-50">
      <div className="flex flex-col items-center space-y-2 p-3 bg-white rounded-lg shadow-lg border border-gray-200">
        <button
          onClick={(e) => {
            e.stopPropagation();
            resetCamera();
          }}
          className="w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Reset Camera
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            toggleCameraRotationLock();
          }}
          className="w-full flex items-center justify-center space-x-2 py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white bg-yellow-500 rounded hover:bg-yellow-600"
        >
          {isCameraRotationLocked ? (
            <>
              <LockIcon fontSize="small" />
              <span>Unlock Camera</span>
            </>
          ) : (
            <>
              <LockOpenIcon fontSize="small" />
              <span>Lock Camera</span>
            </>
          )}
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setHomeCameraView();
          }}
          className="w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white bg-purple-500 rounded hover:bg-purple-600"
        >
          Set Home View
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            goHomeCameraView();
          }}
          disabled={!isHomeViewSet}
          className={`w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white rounded ${
            isHomeViewSet
              ? "bg-green-600 hover:bg-green-700 cursor-pointer"
              : "bg-gray-400 cursor-not-allowed"
          }`}
        >
          Go to Home View
        </button>
        <div className="view-buttons flex flex-wrap justify-center gap-2">
          <button
            onClick={goTopView}
            className="p-2 bg-gray-200 rounded hover:bg-gray-300"
            title="Top View"
          >
            <ArrowUpwardIcon />
          </button>
          <button
            onClick={goBottomView}
            className="p-2 bg-gray-200 rounded hover:bg-gray-300"
            title="Bottom View"
          >
            <ArrowDownwardIcon />
          </button>
          <button
            onClick={goLeftView}
            className="p-2 bg-gray-200 rounded hover:bg-gray-300"
            title="Left View"
          >
            <ArrowLeftIcon />
          </button>
          <button
            onClick={goRightView}
            className="p-2 bg-gray-200 rounded hover:bg-gray-300"
            title="Right View"
          >
            <ArrowRightIcon />
          </button>
          <button
            onClick={goFrontView}
            className="p-2 bg-gray-200 rounded hover:bg-gray-300"
            title="Front View"
          >
            <ArrowForwardIcon />
          </button>
          <button
            onClick={goBackView}
            className="p-2 bg-gray-200 rounded hover:bg-gray-300"
            title="Back View"
          >
            <ArrowBackIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CameraOrientationModal;
