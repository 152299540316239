import React from "react";

const SurgeryPositionModal = ({
  goToSurgeryPosition,
  saveSurgeryPosition,
  addSurgeryPos,
  deleteSurgeryPos,
  setSelectedSurgeryPosId,
  selectedCase,
  selectedSurgeryPosId,
  isPositionValid = true,
}) => {
  // Extract surgery positions from the selected case.
  const surgeryPositions = selectedCase?.items?.surgeryPos || {};

  return (
    <div className="absolute bottom-12 left-4 w-auto flex flex-col items-center z-50">
      <div className="flex flex-col items-center space-y-2 p-3 bg-white rounded-lg shadow-lg border border-gray-200">
        {/* Button to add a new surgery position */}
        {Object.keys(surgeryPositions).length < 5 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              // Prompt for a new surgery position name.
              addSurgeryPos();
            }}
            className="w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white bg-purple-500 rounded hover:bg-purple-600"
          >
            Add Surgery Position
          </button>
        )}

        {/* List buttons for each saved surgery position */}
        {Object.keys(surgeryPositions).length > 0 && (
          <div className="w-full flex flex-col space-y-1">
            {Object.entries(surgeryPositions).map(([id, pos]) => (
              <div
                key={id}
                className={`flex items-center justify-between rounded ${
                  selectedSurgeryPosId === id
                    ? "bg-indigo-700 hover:bg-indigo-800"
                    : "bg-indigo-500 hover:bg-indigo-600"
                }`}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    // Set the selected surgery position id,
                    // then go to that surgery position.
                    setSelectedSurgeryPosId(id);
                    goToSurgeryPosition(id);
                  }}
                  className="flex-1 py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white text-left"
                >
                  {pos.name || "Unnamed Surgery Pos"}
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteSurgeryPos(id);
                  }}
                  className="px-2 text-white hover:text-white"
                  title="Delete Surgery Position"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4" // slightly larger for visibility
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor" // currentColor will be white because of the parent class
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5-4h4m-4 0a1 1 0 00-1 1v1h6V4a1 1 0 00-1-1m-4 0h4"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Only show the save button if at least one surgery position exists */}
        {Object.keys(surgeryPositions).length > 0 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              saveSurgeryPosition(selectedSurgeryPosId);
            }}
            disabled={!isPositionValid}
            className={`w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white rounded ${
              isPositionValid
                ? "bg-green-600 hover:bg-green-700 cursor-pointer"
                : "bg-gray-400 cursor-not-allowed"
            }`}
          >
            Save Surgery Position
          </button>
        )}
      </div>
    </div>
  );
};

export default SurgeryPositionModal;
