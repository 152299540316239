export const ColorCodes = {
    tissue: '#80AE80',
    bone: '#F1D691',
    skin: '#B17A65',
    connective_tissue: '#6FB8D2',
    blood: '#D8654F',
    organ: '#DD8265',
    mass: '#90EE90',
    muscle: '#C06858',
    foreign_object: '#DCF514',
    waste: '#4E3F00',
    teeth: '#FFFADC',
    fat: '#E6DC46',
    gray_matter: '#C8C8EB',
    white_matter: '#FAFAD2',
    nerve: '#F4D631',
    vein: '#0097CE',
    artery: '#D8654F',
    capillary: '#B79CDC',
    ligament: '#B7D6D3',
    tendon: '#98BDCF',
    cartilage: '#6FB8D2',
    meniscus: '#B2D4F2',
    lymph_node: '#44AC64',
    lymphatic_vessel: '#6FC583',
    cerebro_spinal_fluid: '#55BCFF',
    bile: '#00911E',
    urine: '#D6E682',
    feces: '#4E3F00',
    gas: '#DAFFFF',
    fluid: '#AAFAFA',
    edema: '#8CE0E4',
    bleeding: '#BC411C',
    necrosis: '#D8BFD8',
    clot: '#913C42',
    embolism: '#966253',
  
    // Head and Brain
    head: '#B17A65',
    central_nervous_system: '#F4D631',
    brain: '#FAFAE1',
    gray_matter_of_brain: '#C8C8D7',
    telencephalon: '#448362',
    cerebral_cortex: '#80AE80',
    right_frontal_lobe: '#5392A4',
    left_frontal_lobe: '#5392A4',
    right_temporal_lobe: '#A27369',
    left_temporal_lobe: '#A27369',
    right_parietal_lobe: '#8D5D89',
    left_parietal_lobe: '#8D5D89',
    right_occipital_lobe: '#B6A66E',
    left_occipital_lobe: '#B6A66E',
    right_insular_lobe: '#BC87A6',
    left_insular_lobe: '#BC87A6',
    right_limbic_lobe: '#9A96C9',
    left_limbic_lobe: '#9A96C9',
    right_striatum: '#B18CBE',
    left_striatum: '#B18CBE',
    right_caudate_nucleus: '#1E6F55',
    left_caudate_nucleus: '#1E6F55',
    right_putamen: '#D29DA6',
    left_putamen: '#D29DA6',
    right_pallidum: '#30817E',
    left_pallidum: '#30817E',
    right_amygdaloid_complex: '#629970',
    left_amygdaloid_complex: '#629970',
    diencephalon: '#456E35',
    thalamus: '#A67189',
    right_thalamus: '#7A6526',
    left_thalamus: '#7A6526',
    pineal_gland: '#FD87C0',
    midbrain: '#915C6D',
    substantia_nigra: '#2E6583',
    right_substantia_nigra: '#006C70',
    left_substantia_nigra: '#006C70',
    cerebral_white_matter: '#FAFAE1',
    right_superior_longitudinal_fasciculus: '#7F9658',
    left_superior_longitudinal_fasciculus: '#7F9658',
    right_inferior_longitudinal_fasciculus: '#9F74A3',
    left_inferior_longitudinal_fasciculus: '#9F74A3',
    right_arcuate_fasciculus: '#7D669A',
    left_arcuate_fasciculus: '#7D669A',
    right_uncinate_fasciculus: '#6AAE9B',
    left_uncinate_fasciculus: '#6AAE9B',
    right_cingulum_bundle: '#9A9253',
    left_cingulum_bundle: '#9A9253',
    projection_fibers: '#7E7E37',
    right_corticospinal_tract: '#C9A085',
    left_corticospinal_tract: '#C9A085',
    right_optic_radiation: '#4E988D',
    left_optic_radiation: '#4E988D',
    right_medial_lemniscus: '#AE8C67',
    left_medial_lemniscus: '#AE8C67',
    right_superior_cerebellar_peduncle: '#8B7EB1',
    left_superior_cerebellar_peduncle: '#8B7EB1',
    right_middle_cerebellar_peduncle: '#947848',
    left_middle_cerebellar_peduncle: '#947848',
    right_inferior_cerebellar_peduncle: '#BA8787',
    left_inferior_cerebellar_peduncle: '#BA8787',
    optic_chiasm: '#636A18',
    right_optic_tract: '#9CAB6C',
    left_optic_tract: '#9CAB6C',
    right_fornix: '#407B93',
    left_fornix: '#407B93',
    commissural_fibers: '#8A5F4A',
    corpus_callosum: '#61719E',
    posterior_commissure: '#7EA1C5',
    cerebellar_white_matter: '#C2C3A4',
  
    // CSF spaces
    CSF_space: '#55BCFF',
    ventricles_of_brain: '#586AD7',
    right_lateral_ventricle: '#586AD7',
    left_lateral_ventricle: '#586AD7',
    right_third_ventricle: '#586AD7',
    left_third_ventricle: '#586AD7',
    cerebral_aqueduct: '#586AD7',
    fourth_ventricle: '#586AD7',
    subarachnoid_space: '#586AD7',
  
    // Spinal cord
    spinal_cord: '#F4D631',
    gray_matter_of_spinal_cord: '#C8C8D7',
    white_matter_of_spinal_cord: '#FAFAE1',
  
    // Endocrine system of brain
    endocrine_system_of_brain: '#52AE80',
    pituitary_gland: '#399D6E',
    adenohypophysis: '#3C8F53',
    neurohypophysis: '#5CA26D',
  
    // Meninges
    meninges: '#FFF4D1',
    dura_mater: '#FFF4D1',
    arachnoid: '#FFF4D1',
    pia_mater: '#FFF4D1',
  
    // Head structures
    muscles_of_head: '#C9794D',
    salivary_glands: '#46A375',
    lips: '#BC5B5F',
    nose: '#B17A65',
    tongue: '#A6545E',
    soft_palate: '#B6696B',
    right_inner_ear: '#E59376',
    left_inner_ear: '#E59376',
    right_external_ear: '#AE7A5A',
    left_external_ear: '#AE7A5A',
    right_middle_ear: '#C97049',
    left_middle_ear: '#C97049',
    right_eyeball: '#C28E00',
    left_eyeball: '#C28E00',
  
    // Skull
    skull: '#F1D590',
    right_frontal_bone: '#CBB34D',
    left_frontal_bone: '#CBB34D',
    right_parietal_bone: '#E5CC6D',
    left_parietal_bone: '#E5CC6D',
    right_temporal_bone: '#FFF398',
    left_temporal_bone: '#FFF398',
    right_sphenoid_bone: '#D1B955',
    left_sphenoid_bone: '#D1B955',
    right_ethmoid_bone: '#F8DF83',
    left_ethmoid_bone: '#F8DF83',
    occipital_bone: '#FFE68A',
    maxilla: '#C4AC44',
    right_zygomatic_bone: '#FFFFA7',
    right_lacrimal_bone: '#FFFAA0',
    vomer_bone: '#FFED91',
    right_palatine_bone: '#F2D97B',
    left_palatine_bone: '#F2D97B',
    mandible: '#DEC665',
  
    // Neck
    neck: '#B17A65',
    muscles_of_neck: '#D57C6D',
    pharynx: '#B8696C',
    larynx: '#96D0F3',
    thyroid_gland: '#3EA272',
    right_parathyroid_glands: '#3EA272',
    left_parathyroid_glands: '#3EA272',
    skeleton_of_neck: '#F2CE8E',
    hyoid_bone: '#FAD28B',
    cervical_vertebral_column: '#FFFFCF',
  
    // Thorax
    thorax: '#B17A65',
    trachea: '#B6E4FF',
    bronchi: '#AFD8F4',
    right_lung: '#C5A591',
    left_lung: '#C5A591',
    superior_lobe_of_right_lung: '#AC8A73',
    superior_lobe_of_left_lung: '#AC8A73',
    middle_lobe_of_right_lung: '#CAA48C',
    inferior_lobe_of_right_lung: '#E0BAA2',
    inferior_lobe_of_left_lung: '#E0BAA2',
    pleura: '#FFF5D9',
    heart: '#CE6E54',
    right_atrium: '#D27359',
    left_atrium: '#CB6C51',
    atrial_septum: '#E98A70',
    ventricular_septum: '#C36449',
    right_ventricle_of_heart: '#B55539',
    left_ventricle_of_heart: '#98370D',
    mitral_valve: '#9F3F1B',
    tricuspid_valve: '#A64626',
    aortic_valve: '#DA7B61',
    pulmonary_valve: '#E18268',
    aorta: '#E0614C',
    pericardium: '#FFF4D1',
    pericardial_cavity: '#B87A9A',
    esophagus: '#D3AB8F',
    thymus: '#2F9667',
    mediastinum: '#FFF4D1',
    skin_of_thoracic_wall: '#AD7958',
    muscles_of_thoracic_wall: '#BC5F4C',
    skeleton_of_thorax: '#FFEFAC',
    thoracic_vertebral_column: '#E2CA86',
    ribs: '#FDE89E',
    sternum: '#F4D99A',
    right_clavicle: '#CDB36C',
    left_clavicle: '#CDB36C',
  
    // Abdomen
    abdominal_cavity: '#BA7CA1',
    abdomen: '#B17A65',
    peritoneum: '#FFFFDC',
    omentum: '#EAEAC2',
    peritoneal_cavity: '#CC8EB2',
    retroperitoneal_space: '#B47799',
    stomach: '#D88469',
    duodenum: '#FFFDE5',
    small_bowel: '#CDA78E',
    colon: '#CCA88F',
    anus: '#FFE0C7',
    liver: '#DD8265',
    biliary_tree: '#00911E',
    gallbladder: '#8B9662',
    pancreas: '#F9B46F',
    spleen: '#9D6CA2',
    urinary_system: '#CB8874',
    right_kidney: '#B96653',
    left_kidney: '#B96653',
    right_ureter: '#F7B6A4',
    left_ureter: '#F7B6A4',
    urinary_bladder: '#DE9A84',
    urethra: '#7CBADF',
    right_adrenal_gland: '#F9BA96',
    left_adrenal_gland: '#F9BA96',
  
    // Female internal genitalia
    female_internal_genitalia: '#F4AA93',
    uterus: '#FFB59E',
    right_fallopian_tube: '#FFBEA5',
    left_fallopian_tube: '#E39982',
    right_ovary: '#D58D71',
    left_ovary: '#D58D71',
    vagina: '#C17B67',
  
    // Male internal genitalia
    male_internal_genitalia: '#D8927F',
    prostate: '#E69E8C',
    right_seminal_vesicle: '#F5AC93',
    left_seminal_vesicle: '#F5AC93',
    right_deferent_duct: '#F1AC97',
    left_deferent_duct: '#F1AC97',
  
    // Abdominal wall
    skin_of_abdominal_wall: '#B17C5C',
    muscles_of_abdominal_wall: '#AB5544',
    skeleton_of_abdomen: '#D9C683',
    lumbar_vertebral_column: '#D4BC66',
  
    // External genitalia
    female_external_genitalia: '#B98786',
    male_external_genitalia: '#B98786',
  
    // Upper limb
    skeleton_of_upper_limb: '#C6AF7D',
    muscles_of_upper_limb: '#C2624F',
    right_upper_limb: '#B17A65',
    left_upper_limb: '#B17A65',
    right_shoulder: '#B17A65',
    left_shoulder: '#B17A65',
    right_arm: '#B17A65',
    left_arm: '#B17A65',
    right_elbow: '#B17A65',
    left_elbow: '#B17A65',
    right_forearm: '#B17A65',
    left_forearm: '#B17A65',
    right_wrist: '#B17A65',
    left_wrist: '#B17A65',
    right_hand: '#B17A65',
    left_hand: '#B17A65',
  
    // Lower limb
    skeleton_of_lower_limb: '#FFEEAA',
    muscles_of_lower_limb: '#CE6F5D',
    right_lower_limb: '#B17A65',
    left_lower_limb: '#B17A65',
    right_hip: '#B17A65',
    left_hip: '#B17A65',
    right_thigh: '#B17A65',
    left_thigh: '#B17A65',
    right_knee: '#B17A65',
    left_knee: '#B17A65',
    right_leg: '#B17A65',
    left_leg: '#B17A65',
    right_foot: '#B17A65',
    left_foot: '#B17A65',
  
    // Peripheral nervous system
    peripheral_nervous_system: '#D8BA00',
    autonomic_nerve: '#FFE24D',
    sympathetic_trunk: '#FFF36A',
    cranial_nerves: '#FFEA5C',
    vagus_nerve: '#F0D223',
    peripheral_nerve: '#E0C200',
  
    // Circulatory system
    circulatory_system: '#D5634F',
    systemic_arterial_system: '#D96651',
    systemic_venous_system: '#0093CA',
    pulmonary_arterial_system: '#007AAB',
    pulmonary_venous_system: '#BA4D40',
  
    // Lymphatic
    lymphatic_system: '#6FC583',
  
    // Other
    needle: '#F0FF1E',
    region_0: '#B9E83D',
    region_1: '#00E2FF',
    region_2: '#FB9FFF',
    region_3: '#E6A91D',
    region_4: '#00C271',
    region_5: '#68A0F9',
    region_6: '#DD6C9E',
    region_7: '#898E00',
    region_8: '#E64600',
    region_9: '#009300',
    region_10: '#0093F8',
    region_11: '#E700CE',
    region_12: '#814E00',
    region_13: '#007400',
    region_14: '#0000FF',
    region_15: '#9D0000',
    unknown: '#646482',
    cyst: '#CDCD64',
  };
  