import { Roles } from "../../constants/roles";

const ActionButtons = ({
  modelInfos,
  handleSTLFileSelect,
  handleDICOMFileSelect,
  handleViewerClick,
  handleEditClick,
  handleCommentCase,
  handleShare,
  handleUserPermissions,
  handleViewDicom,
  isMobile,
  userRole,
}) => {
  return (
    <div
      className={
        isMobile
          ? "w-full flex flex-col space-y-4"
          : "flex flex-col items-end space-y-2 w-1/3"
      }
    >
      {/* <button
        onClick={handleViewDicom}
        className={`bg-purple-600 text-white px-4 py-2 rounded ${
          isMobile
            ? "w-full text-center"
            : "w-60 text-center transition-colors duration-300 hover:bg-purple-700"
        }`}
      >
        View Dicom
      </button> */}

      {modelInfos.length > 0 && (
        <button
          onClick={handleViewerClick}
          className={`bg-blue-600 text-white px-4 py-2 rounded ${
            isMobile
              ? "w-full text-center"
              : "w-60 text-center transition-colors duration-300 hover:bg-blue-700"
          }`}
        >
          Open in 3D Viewer
        </button>
      )}

      {[Roles.ADMIN, Roles.CREATOR, Roles.OWNER, Roles.EDITOR].includes(
        userRole
      ) && (
        <>
          <label
            className={`bg-sky-600 text-white px-4 py-2 rounded cursor-pointer ${
              isMobile
                ? "w-full text-center"
                : "w-60 text-center transition-colors duration-300 hover:bg-sky-700"
            }`}
          >
            Upload STL
            <input
              type="file"
              accept=".stl"
              multiple
              className="hidden"
              onChange={handleSTLFileSelect}
            />
          </label>
          <label
            className={`bg-sky-600 text-white px-4 py-2 rounded cursor-pointer ${
              isMobile
                ? "w-full text-center"
                : "w-60 text-center transition-colors duration-300 hover:bg-sky-700"
            }`}
          >
            Upload DICOM (ZIP)
            <input
              type="file"
              accept=".zip, .rar"
              className="hidden"
              onChange={handleDICOMFileSelect}
            />
          </label>
        </>
      )}

      <button
        onClick={handleCommentCase}
        className={`bg-teal-600 text-white px-4 py-2 rounded ${
          isMobile
            ? "w-full text-center"
            : "w-60 text-center transition-colors duration-300 hover:bg-teal-700"
        }`}
      >
        Comment Case
      </button>

      {[Roles.ADMIN, Roles.CREATOR, Roles.OWNER, Roles.EDITOR].includes(
        userRole
      ) && (
        <button
          onClick={handleEditClick}
          className={`bg-green-700 text-white px-4 py-2 rounded ${
            isMobile
              ? "w-full text-center"
              : "w-60 text-center transition-colors duration-300 hover:bg-green-800"
          }`}
        >
          Edit Case
        </button>
      )}

      {[Roles.ADMIN, Roles.CREATOR, Roles.OWNER].includes(userRole) && (
        <button
          onClick={handleShare}
          className={`bg-teal-700 text-white px-4 py-2 rounded ${
            isMobile
              ? "w-full text-center"
              : "w-60 text-center transition-colors duration-300 hover:bg-teal-800"
          }`}
        >
          Share
        </button>
      )}

      {[Roles.ADMIN, Roles.CREATOR, Roles.OWNER].includes(userRole) && (
        <button
          onClick={handleUserPermissions}
          className={`bg-gray-500 text-white px-4 py-2 rounded ${
            isMobile
              ? "w-full text-center"
              : "w-60 text-center transition-colors duration-300 hover:bg-gray-600"
          }`}
        >
          User Permissions
        </button>
      )}
    </div>
  );
};

export default ActionButtons;
