import React from "react";

const DistanceMeasuringModal = ({
  clearMeasurements,
  closePolygon,
  setIsMeasurementModalOpen,
  pathPoints,
}) => {
  return (
    <div className="absolute bottom-12 left-4 w-auto flex flex-col items-center z-50">
      <div className="flex flex-col items-center space-y-2 p-3 bg-white rounded-lg shadow-lg border border-gray-200">
        <button
          onClick={(e) => {
            e.stopPropagation();
            clearMeasurements();
          }}
          className="w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white bg-red-500 rounded hover:bg-red-600"
        >
          Clear Points
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            closePolygon();
          }}
          className="w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white bg-gray-500 rounded hover:bg-gray-600"
        >
          Close Polygon
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsMeasurementModalOpen(true);
          }}
          disabled={pathPoints.length < 2}
          className={`w-full py-2 px-3 text-sm font-medium md:text-xs md:font-normal text-white rounded ${
            pathPoints.length > 1
              ? "bg-green-600 hover:bg-green-700 cursor-pointer"
              : "bg-gray-400 cursor-not-allowed"
          }`}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default DistanceMeasuringModal;
