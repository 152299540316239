import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { loginFailure, loginStart, loginSuccess } from "../../redux/userRedux";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../../hooks/useIsMobile";
import getErrorMessage from "../../utils/errorUtils";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LoginFormInputFields from "./LoginFormInputFields";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector((state) => state.user);
  const [handleError, setHandleError] = useState("");
  const [resetState, setResetState] = useState({
    resetEmailSent: false,
    resetEmailError: "",
    forgotMyPassword: false,
  });
  const isMobile = useIsMobile();

  const handleSignIn = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      dispatch(loginStart());
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          if (user.emailVerified) {
            const uid = user.uid;
            dispatch(loginSuccess(uid));
            navigate("/dashboard");
          } else {
            dispatch(loginFailure(true));
            setHandleError("Please verify your email before signing in.");
          }
        })
        .catch((error) => {
          dispatch(loginFailure(true));
          setHandleError(getErrorMessage(error.message));
          setTimeout(() => {
            setHandleError("");
          }, 5000);
        });
    } catch (error) {
      dispatch(loginFailure(error.message));
      setHandleError(getErrorMessage(error.message));
      setTimeout(() => {
        setHandleError("");
      }, 5000);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const email = e.target.resetEmail.value;
    try {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setResetState((prevState) => ({
            ...prevState,
            resetEmailSent: true,
            resetEmailError: "",
          }));
          setTimeout(() => {
            setResetState((prevState) => ({
              ...prevState,
              resetEmailSent: false,
              forgotMyPassword: false,
            }));
          }, 5000);
        })
        .catch((error) => {
          setResetState((prevState) => ({
            ...prevState,
            resetEmailSent: false,
            resetEmailError: getErrorMessage(error.message),
          }));
          setTimeout(() => {
            setResetState((prevState) => ({
              ...prevState,
              resetEmailError: "",
            }));
          }, 5000);
        });
    } catch (error) {
      setResetState((prevState) => ({
        ...prevState,
        resetEmailSent: false,
        resetEmailError: getErrorMessage(error.message),
      }));
      setTimeout(() => {
        setResetState((prevState) => ({
          ...prevState,
          resetEmailError: "",
        }));
      }, 5000);
    }
  };

  const goBackToLogin = () => {
    setResetState({
      resetEmailSent: false,
      resetEmailError: "",
      forgotMyPassword: false,
    });
  };

  const goForgotMyPassword = () => {
    setHandleError("");
    setResetState((prevState) => ({
      ...prevState,
      forgotMyPassword: true,
    }));
  };

  if (isMobile) {
    return (
      <div
        className="px-4 pt-16 overflow-auto h-screen"
        style={{ backgroundColor: "#c0cacb" }}
      >
        <div className="p-8 rounded-xl bg-blue-100/60 w-full max-w-lg mx-auto">
          <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-500 text-blue-500">
            {resetState.forgotMyPassword ? "Reset Password" : "Login"}
          </h2>
          {resetState.forgotMyPassword ? (
            <ForgotPasswordForm
              handleResetPassword={handleResetPassword}
              resetEmailSent={resetState.resetEmailSent}
              resetEmailError={resetState.resetEmailError}
              goBackToLogin={goBackToLogin}
            />
          ) : (
            <LoginFormInputFields
              handleSignIn={handleSignIn}
              isFetching={isFetching}
              goForgotMyPassword={goForgotMyPassword}
              error={error}
              handleError={handleError}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex items-center justify-center h-screen"
      style={{ backgroundColor: "#c0cacb" }}
    >
      <div className="flex-1 max-w-xl mx-auto">
        <div className="p-8 rounded-xl bg-blue-100/60 ml-16">
          <div>
            <h2 className="text-2xl font-bold mb-6 pb-6 border-b-2 text-center border-blue-500 text-blue-500">
              {resetState.forgotMyPassword ? "Reset Password" : "Login"}
            </h2>
            {resetState.forgotMyPassword ? (
              <ForgotPasswordForm
                handleResetPassword={handleResetPassword}
                resetEmailSent={resetState.resetEmailSent}
                resetEmailError={resetState.resetEmailError}
                goBackToLogin={goBackToLogin}
              />
            ) : (
              <LoginFormInputFields
                handleSignIn={handleSignIn}
                isFetching={isFetching}
                goForgotMyPassword={goForgotMyPassword}
                error={error}
                handleError={handleError}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex-1">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/d-viewer-de717.appspot.com/o/source%2Fhello.jpg?alt=media&token=1fa16b85-b32e-4711-89e4-0151e06797ba"
          alt="3d model"
          className="h-screen"
        />
      </div>
    </div>
  );
};

export default LoginForm;
