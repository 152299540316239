import { useState, useEffect } from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { ChromePicker } from "react-color";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const ModelsSidebar = ({
  modelsRefs,
  onOpacityChange,
  onVisibilityChange,
  onColorChange,
  onColorSave,
  onMoveToStructure,
}) => {
  const [visibility, setVisibility] = useState([]);
  const [opacity, setOpacity] = useState([]);
  const [colors, setColors] = useState([]);
  const [showColorPicker, setShowColorPicker] = useState([]);

  useEffect(() => {
    setVisibility(modelsRefs.map((model) => model.visible));
    setOpacity(modelsRefs.map((model) => model.material.opacity));
    setColors(modelsRefs.map((model) => model.material.color.getStyle()));
    setShowColorPicker(modelsRefs.map(() => false));
  }, [modelsRefs]);

  const handleVisibilityChange = (index) => {
    const model = modelsRefs[index];
    if (model) {
      const newVisibility = [...visibility];
      newVisibility[index] = !model.visible;

      // Automatically adjust opacity based on visibility
      const newOpacity = [...opacity];
      if (newVisibility[index]) {
        model.material.opacity = 1; // Show model with opacity 1
        newOpacity[index] = 1;
      } else {
        model.material.opacity = 0; // Hide model with opacity 0
        newOpacity[index] = 0;
      }

      model.material.needsUpdate = true;
      model.visible = newVisibility[index];

      setVisibility(newVisibility);
      setOpacity(newOpacity);

      onVisibilityChange(index, newVisibility[index]);
      onOpacityChange(index, newOpacity[index]);
    }
  };

  const handleOpacityChange = (index, value) => {
    const model = modelsRefs[index];
    if (model) {
      const newOpacity = value;
      model.material.opacity = newOpacity;
      model.material.needsUpdate = true;
      const opacityChange = [...opacity];
      opacityChange[index] = newOpacity;
      setOpacity(opacityChange);

      // Automatically toggle visibility based on opacity
      if (newOpacity === 0 && model.visible) {
        handleVisibilityChange(index); // Hide the model
      } else if (newOpacity > 0 && !model.visible) {
        handleVisibilityChange(index); // Show the model
      }

      onOpacityChange(index, newOpacity);
    }
  };

  const handleColorChange = (color, index) => {
    const newColors = [...colors];
    newColors[index] = color.hex;
    setColors(newColors);
    onColorChange(index, color.hex);
  };

  const toggleColorPicker = (index) => {
    const newShowColorPicker = showColorPicker.map((show, i) =>
      i === index ? !show : false
    );
    setShowColorPicker(newShowColorPicker);
  };

  const handleSaveColor = (index) => {
    const selectedColor = colors[index];
    onColorSave(index, selectedColor);

    const newShowColorPicker = [...showColorPicker];
    newShowColorPicker[index] = false;
    setShowColorPicker(newShowColorPicker);
  };

  const makeOthersInvisible = (index) => {
    const newVisibility = modelsRefs.map((_, i) => i === index);
    const newOpacity = modelsRefs.map((_, i) => (i === index ? 1 : 0));
    modelsRefs.forEach((model, i) => {
      model.visible = newVisibility[i];
      model.material.opacity = newOpacity[i];
      model.material.needsUpdate = true;
      onVisibilityChange(i, newVisibility[i]);
      onOpacityChange(i, newOpacity[i]);
    });
    setVisibility(newVisibility);
    setOpacity(newOpacity);
  };

  const showAllStructures = () => {
    const newVisibility = modelsRefs.map(() => true);
    const newOpacity = modelsRefs.map(() => 1);
    modelsRefs.forEach((model, i) => {
      model.visible = true;
      model.material.opacity = 1;
      model.material.needsUpdate = true;
      onVisibilityChange(i, true);
      onOpacityChange(i, 1);
    });
    setVisibility(newVisibility);
    setOpacity(newOpacity);
  };

  const moveToStructure = (index) => {
    if (onMoveToStructure) {
      onMoveToStructure(index);
    }
  };
  const handleCloseColorPicker = (index) => {
    const newShowColorPicker = [...showColorPicker];
    newShowColorPicker[index] = false;
    setShowColorPicker(newShowColorPicker);
  };

  return (
    <div className="absolute right-0 top-10 w-72 md:w-44 bg-gray-100 overflow-y-auto shadow-lg z-20 h-[80vh]">
      <div className="bg-blue-500 text-white text-center h-12 flex items-center justify-between font-semibold px-4">
        <button
          className="px-4 py-1 rounded hover:bg-blue-700 transition duration-150 ease-in-out text-sm font-semibold"
          onClick={showAllStructures}
        >
          Show All
        </button>
        <span className="flex-grow text-center">SEGMENTED STRUCTURES</span>
      </div>
      <p className="text-sm text-gray-800 p-2 text-center">
        Note: All functions work on <strong>visible</strong> structures only.
      </p>
      <ul className="list-none m-0 p-0">
        {modelsRefs.map((modelRef, index) => (
          <li
            key={index}
            className="cursor-pointer p-4 hover:bg-blue-100 bg-gray-200 border-b-2 border-gray-300 transition-colors duration-150 ease-in-out flex flex-col space-y-2"
          >
            <div className="flex justify-between space-x-4 md:space-x-0 w-full">
              <div className="flex items-center space-x-2 md:space-x-1">
                <div className="relative">
                  <button
                    style={{ backgroundColor: colors[index] }}
                    className="w-6 h-6 md:w-4 md:h-4 rounded-full border-2 border-gray-300"
                    onClick={() => toggleColorPicker(index)}
                  ></button>

                  {showColorPicker[index] && (
                    <div className="absolute z-10 rounded bg-gray-100 w-56 md:w-36 flex flex-col items-center">
                      <ChromePicker
                        color={colors[index]}
                        onChange={(color) => handleColorChange(color, index)}
                        disableAlpha={true}
                        styles={{
                          default: {
                            picker: {
                              width: "100%",
                              boxSizing: "border-box",
                              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
                            },
                          },
                        }}
                      />
                      <div className="flex space-x-2 my-2">
                        <button
                          className="px-4 py-1 text-white text-center bg-gray-500 rounded-md hover:bg-gray-600"
                          onClick={() => handleCloseColorPicker(index)}
                        >
                          Close
                        </button>
                        <button
                          className="px-4 py-1 text-white text-center bg-blue-500 rounded-md hover:bg-blue-600"
                          onClick={() => handleSaveColor(index)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`text-gray-700 font-medium md:font-normal ${
                    modelRef.name.length > 20
                      ? "text-xs"
                      : modelRef.name.length > 10
                      ? "text-sm md:text-xs"
                      : "text-base md:text-sm"
                  }`}
                >
                  {modelRef.name}
                </div>
              </div>
              <div className="flex items-center space-x-2 md:space-x-1">
                {visibility[index] ? (
                  <VisibilityOutlinedIcon
                    className="text-gray-600 cursor-pointer"
                    onClick={() => handleVisibilityChange(index)}
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    className="text-gray-300 cursor-pointer"
                    onClick={() => handleVisibilityChange(index)}
                  />
                )}
              </div>
            </div>
            <div className="flex items-center space-x-2 md:space-x-0">
              <span className="mr-2 md:text-xs">Opacity:</span>
              <Slider
                min={0}
                max={1}
                step={0.1}
                value={opacity[index]}
                onChange={(value) => handleOpacityChange(index, value)}
                className="w-auto"
              />
            </div>
            <div className="flex flex-row space-x-2 space-y-0 md:flex-col md:space-x-0 md:space-y-2">
              <button
                className="px-2 py-1 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded text-sm"
                onClick={() => makeOthersInvisible(index)}
              >
                Hide Others
              </button>
              <button
                className="px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded text-sm"
                onClick={() => moveToStructure(index)}
              >
                Move To Structure
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ModelsSidebar;
