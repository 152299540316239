import { useCallback, useState } from "react";
import * as THREE from "three";

const useCamera = (
  cameraRef,
  controlsRef,
  modelRef,
  initialCameraPosition,
  initialCameraTarget
) => {
  const [homeOrientation, setHomeOrientation] = useState({
    position: null,
    target: null,
    quaternion: null,
    up: null,
  });
  const [isCameraOrientationMode, setIsCameraOrientationMode] = useState(false);
  const [isCameraRotationLocked, setIsCameraRotationLocked] = useState(false);

  // Reset camera to its initial position and target.
  const resetCamera = useCallback(() => {
    if (!initialCameraPosition || !initialCameraTarget) return;

    // Reset camera position
    cameraRef.current.position.set(
      initialCameraPosition.x,
      initialCameraPosition.y,
      initialCameraPosition.z
    );

    // Reset camera target
    controlsRef.current.target.set(
      initialCameraTarget.x,
      initialCameraTarget.y,
      initialCameraTarget.z
    );

    if (initialCameraPosition.initialQuaternion) {
      cameraRef.current.quaternion.copy(
        initialCameraPosition.initialQuaternion
      );
    }

    if (initialCameraPosition.up) {
      cameraRef.current.up.copy(initialCameraPosition.up);
    } else {
      cameraRef.current.up.set(0, 1, 0);
    }

    cameraRef.current.lookAt(controlsRef.current.target);

    // Update controls
    controlsRef.current.update();
  }, [cameraRef, controlsRef, initialCameraPosition, initialCameraTarget]);

  // Capture the current camera view as the home view.
  const setHomeCameraView = useCallback(() => {
    if (cameraRef.current && controlsRef.current) {
      const currentPosition = cameraRef.current.position.clone();
      const currentTarget = controlsRef.current.target.clone();
      const currentQuaternion = cameraRef.current.quaternion.clone();
      const currentUp = cameraRef.current.up.clone();
      setHomeOrientation({
        position: currentPosition,
        target: currentTarget,
        quaternion: currentQuaternion,
        up: currentUp,
      });
      console.log(
        "Home camera view saved:",
        currentPosition,
        currentTarget,
        currentQuaternion,
        currentUp
      );
    }
  }, [cameraRef, controlsRef, setHomeOrientation]);

  // Animate the camera and controls back to the saved home view.
  const goHomeCameraView = useCallback(() => {
    if (
      !homeOrientation.position ||
      !homeOrientation.target ||
      !homeOrientation.quaternion ||
      !homeOrientation.up
    ) {
      console.warn("Home camera view has not been set yet.");
      return;
    }

    // Set camera position
    cameraRef.current.position.copy(homeOrientation.position);

    // Set camera target
    controlsRef.current.target.copy(homeOrientation.target);

    // Restore camera orientation (quaternion) and up vector
    cameraRef.current.quaternion.copy(homeOrientation.quaternion);
    cameraRef.current.up.copy(homeOrientation.up);

    // Ensure the camera looks at the target
    cameraRef.current.lookAt(homeOrientation.target);

    // Update controls
    controlsRef.current.update();
  }, [homeOrientation, cameraRef, controlsRef]);

  // This toggles the TrackballControls' "noRotate" property.
  const toggleCameraRotationLock = useCallback(() => {
    setIsCameraRotationLocked((prev) => {
      const newLockValue = !prev;
      if (controlsRef.current) {
        // When noRotate is true, rotation is disabled.
        controlsRef.current.noRotate = newLockValue;
        controlsRef.current.update();
      }
      return newLockValue;
    });
  }, [controlsRef]);

  const computeViewParameters = useCallback(() => {
    // Compute the bounding box of the model
    const boundingBox = new THREE.Box3().setFromObject(modelRef.current);
    const center = new THREE.Vector3();
    boundingBox.getCenter(center);

    // Get the size and compute the maximum dimension
    const size = boundingBox.getSize(new THREE.Vector3());
    const maxDimension = Math.max(size.x, size.y, size.z);

    // Distance multiplier can be adjusted – here we use 2× the max dimension
    const distance = maxDimension * 2;

    return { center, distance };
  }, [modelRef]);

  const goTopView = useCallback(() => {
    resetCamera();
    const { center, distance } = computeViewParameters();
    cameraRef.current.position.set(center.x, center.y, center.z + distance);
    controlsRef.current.target.copy(center);
    cameraRef.current.lookAt(center);
    controlsRef.current.update();
  }, [resetCamera, cameraRef, controlsRef, computeViewParameters]);

  const goBottomView = useCallback(() => {
    resetCamera();
    const { center, distance } = computeViewParameters();
    cameraRef.current.position.set(center.x, center.y, center.z - distance);
    controlsRef.current.target.copy(center);
    cameraRef.current.lookAt(center);
    controlsRef.current.update();
  }, [resetCamera, cameraRef, controlsRef, computeViewParameters]);

  const goLeftView = useCallback(() => {
    resetCamera();
    const { center, distance } = computeViewParameters();
    cameraRef.current.position.set(center.x - distance, center.y, center.z);
    cameraRef.current.up.set(0, 0, 1);
    controlsRef.current.target.copy(center);
    cameraRef.current.lookAt(center);
    controlsRef.current.update();
  }, [resetCamera, cameraRef, controlsRef, computeViewParameters]);

  const goRightView = useCallback(() => {
    resetCamera();
    const { center, distance } = computeViewParameters();
    cameraRef.current.position.set(center.x + distance, center.y, center.z);
    cameraRef.current.up.set(0, 0, 1);
    controlsRef.current.target.copy(center);
    cameraRef.current.lookAt(center);
    controlsRef.current.update();
  }, [resetCamera, cameraRef, controlsRef, computeViewParameters]);

  const goFrontView = useCallback(() => {
    resetCamera();
    const { center, distance } = computeViewParameters();
    cameraRef.current.position.set(center.x, center.y - distance, center.z);
    cameraRef.current.up.set(0, 0, 1);
    controlsRef.current.target.copy(center);
    cameraRef.current.lookAt(center);
    controlsRef.current.update();
  }, [resetCamera, cameraRef, controlsRef, computeViewParameters]);

  const goBackView = useCallback(() => {
    resetCamera();
    const { center, distance } = computeViewParameters();
    // Position the camera
    cameraRef.current.position.set(center.x, center.y + distance, center.z);
    // Ensure the camera’s up vector is what you expect
    cameraRef.current.up.set(0, 0, 1);
    controlsRef.current.target.copy(center);
    cameraRef.current.lookAt(center);
    controlsRef.current.update();
  }, [resetCamera, cameraRef, controlsRef, computeViewParameters]);

  return {
    resetCamera,
    setHomeCameraView,
    goHomeCameraView,
    homeOrientation,
    isCameraOrientationMode,
    setIsCameraOrientationMode,
    isCameraRotationLocked,
    toggleCameraRotationLock,
    goTopView,
    goBottomView,
    goLeftView,
    goRightView,
    goFrontView,
    goBackView,
  };
};

export default useCamera;
