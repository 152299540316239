import React from "react";

const ScalpelModal = ({
  isMobile,
  showOptions,
  setShowOptions,
  cutHeight,
  setCutHeight,
  modelRef,
  selectedModels,
  setSelectedModels,
  setProcessing,
  modifyGeometryAndAddToScene,
}) => {
  return (
    <div className="absolute bottom-12 left-4 w-auto md:w-1/4 flex flex-col items-start z-50">
      <div className="flex flex-col items-start space-y-3 p-3 bg-white rounded-lg shadow-lg border border-gray-200">
        {showOptions && (
          <>
            <input
              type="number"
              value={cutHeight}
              onChange={(e) => setCutHeight(parseFloat(e.target.value))}
              className="py-1 px-2 w-full bg-white border border-gray-300 rounded text-sm"
              placeholder="Height"
            />

            {/* Scrollable list of all items, with only 3 visible */}
            <div
              className={`flex flex-col space-y-2 overflow-y-auto ${
                isMobile ? "max-h-28" : "max-h-36"
              }`}
            >
              {modelRef.current.children.map((model, index) => (
                <label
                  key={model.name}
                  className="flex items-center space-x-2 w-full"
                >
                  <input
                    type="checkbox"
                    checked={selectedModels.includes(index)}
                    onChange={() => {
                      setSelectedModels((prevSelectedModels) =>
                        prevSelectedModels.includes(index)
                          ? prevSelectedModels.filter((i) => i !== index)
                          : [...prevSelectedModels, index]
                      );
                    }}
                  />
                  <span className="text-sm md:text-xs text-gray-700">
                    {model.name}
                  </span>
                </label>
              ))}
            </div>

            {/* Buttons aligned next to each other */}
            <div className="flex w-full justify-between items-center space-x-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setProcessing(true);
                  setTimeout(() => {
                    modifyGeometryAndAddToScene();
                  }, 100);
                }}
                className="flex-1 py-2 px-3 text-sm md:text-xs font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600"
              >
                Cut
              </button>
              <button
                onClick={() => setShowOptions(!showOptions)}
                className="py-2 px-3 text-sm md:text-xs text-gray-700 hover:text-gray-900 border border-gray-300 rounded-md"
              >
                Hide
              </button>
            </div>
          </>
        )}

        {/* Show button when options are hidden */}
        {!showOptions && (
          <button
            onClick={() => setShowOptions(true)}
            className="self-end py-2 px-3 text-sm md:text-xs text-gray-700 hover:text-gray-900 border border-gray-300 rounded-md"
          >
            Show
          </button>
        )}
      </div>
    </div>
  );
};

export default ScalpelModal;
