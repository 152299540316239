import React from "react";
import * as THREE from "three";

const Pins = ({ pins, cameraRef, containerRef, moveToAnnotation }) => {
  return pins.map((pin, index) => {
    if (!pin.show) {
      return null;
    }

    // Get the position of the pin in world coordinates
    const worldPosition = new THREE.Vector3(
      pin.coordinates.x,
      pin.coordinates.y,
      pin.coordinates.z
    );

    // Project the world coordinates to screen coordinates
    const screenPosition = worldPosition.project(cameraRef.current);

    // Convert screen coordinates to CSS pixels
    const x = ((screenPosition.x + 1) / 2) * containerRef.current.clientWidth;
    const y = ((-screenPosition.y + 1) / 2) * containerRef.current.clientHeight;

    return (
      <button
        key={index}
        id={`pin-${index}`}
        className="absolute transition-all duration-75 ease-in-out ui-outside-scene" // Smooth movement
        style={{
          top: `${y}px`,
          left: `${x}px`,
          transform: "translate(-50%, -50%)", // Center the pin
        }}
        onClick={() => moveToAnnotation(pin.coordinates)} // Function to handle click
      >
        <div
          className="relative flex items-center justify-center w-8 h-8 bg-gray-700 bg-opacity-50 border border-gray-300 rounded-full shadow-lg hover:bg-gray-700 hover:shadow-xl hover:scale-110 transition-transform"
          title={pin.annotation}
        >
          <span className="text-white text-sm font-bold">{index + 1}</span>
        </div>
      </button>
    );
  });
};

export default Pins;
