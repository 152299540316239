import Navbar from "../components/common/Navbar";
import ProfilePageView from "../components/profile/ProfilePageView";

const ProfilePage = () => {
  return (
    <>
      <Navbar />
      <ProfilePageView />
    </>
  );
};

export default ProfilePage;
