import React from "react";

const DrillModal = ({ brushSize, setBrushSize }) => {
  return (
    <div className="absolute bottom-12 left-4 w-auto md:w-1/4 flex flex-col items-center z-50">
      <div className="flex flex-col items-center space-y-2 p-2 rounded bg-white shadow-md">
        <input
          type="range"
          min="0.1"
          max="5"
          step="0.1"
          value={brushSize}
          onChange={(e) => setBrushSize(parseFloat(e.target.value))}
          className="w-full mb-2"
        />
        <label className="text-gray-700 text-sm md:text-xs">
          Brush: {brushSize}
        </label>
      </div>
    </div>
  );
};

export default DrillModal;
