import { useEffect, useRef, useState, useCallback } from "react";
import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";

const useDrillMode = (
  sceneRef,
  cameraRef,
  modelRef,
  containerRef,
  undoStack,
  undoIndex
) => {
  const drillBrushRef = useRef(null);
  const [isDrillMode, setIsDrillMode] = useState(false);
  const [brushActive, setBrushActive] = useState(false);
  const [brushSize, setBrushSize] = useState(1);

  const initializeBrush = useCallback((size, thickness = 5) => {
    const brushSegments = [];
    const segments = 50; // Number of segments for the circle
    for (let i = 0; i < segments; i++) {
      const nexti = (i + 1) % segments;
      const x1 = Math.sin((2 * Math.PI * i) / segments) * size;
      const y1 = Math.cos((2 * Math.PI * i) / segments) * size;
      const x2 = Math.sin((2 * Math.PI * nexti) / segments) * size;
      const y2 = Math.cos((2 * Math.PI * nexti) / segments) * size;
      brushSegments.push(x1, y1, 0, x2, y2, 0); // Flat array for LineGeometry
    }

    const geometry = new LineGeometry();
    geometry.setPositions(brushSegments);

    const material = new LineMaterial({
      color: 0xfb8c00,
      linewidth: thickness, // Line thickness
    });

    material.resolution.set(window.innerWidth, window.innerHeight); // Needed for Line2

    const brush = new Line2(geometry, material);
    return brush;
  }, []);

  const performDrill = useCallback(
    (intersect) => {
      const { object } = intersect;
      const geometry = object.geometry;
      const positionAttribute = geometry.attributes.position;
      const normalAttribute = geometry.attributes.normal;

      const positions = positionAttribute.array;
      const normals = normalAttribute.array;

      const deletedTrianglesList = [];
      const verticesToRemove = new Set();

      for (let i = 0; i < positions.length; i += 9) {
        const v1 = new THREE.Vector3(
          positions[i],
          positions[i + 1],
          positions[i + 2]
        );
        const v2 = new THREE.Vector3(
          positions[i + 3],
          positions[i + 4],
          positions[i + 5]
        );
        const v3 = new THREE.Vector3(
          positions[i + 6],
          positions[i + 7],
          positions[i + 8]
        );

        const center = new THREE.Vector3()
          .addVectors(v1, v2)
          .add(v3)
          .divideScalar(3);

        if (center.distanceTo(intersect.point) < brushSize) {
          deletedTrianglesList.push({
            vertices: positions.slice(i, i + 9),
            normals: normals.slice(i, i + 9),
            indices: [i, i + 3, i + 6],
          });

          verticesToRemove.add(i);
          verticesToRemove.add(i + 3);
          verticesToRemove.add(i + 6);
        }
      }

      if (deletedTrianglesList.length > 0) {
        const drillUndo = {
          type: "drill",
          geometryId: object.name,
          deletedTrianglesList,
          intersectPoint: intersect.point.clone(),
          brushSize,
        };

        undoStack.current = undoStack.current.slice(0, undoIndex.current);

        undoStack.current.push(drillUndo);
        undoIndex.current += 1;
      }

      const newPositions = [];
      const newNormals = [];

      for (let i = 0; i < positions.length; i += 3) {
        if (!verticesToRemove.has(i)) {
          newPositions.push(positions[i], positions[i + 1], positions[i + 2]);
          newNormals.push(normals[i], normals[i + 1], normals[i + 2]);
        }
      }

      const newGeometry = new THREE.BufferGeometry();
      newGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(newPositions, 3)
      );
      newGeometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(newNormals, 3)
      );

      object.geometry.dispose();
      object.geometry = newGeometry;
    },
    [brushSize, undoStack, undoIndex]
  );

  const undoDrill = useCallback(
    (objectId, deletedTrianglesList) => {
      if (!modelRef.current) return;

      const object = modelRef.current.children.find(
        (child) => child.name === objectId
      );
      if (!object) {
        console.error(`Object with ID ${objectId} not found.`);
        return;
      }

      const geometry = object.geometry;
      const positionAttribute = geometry.attributes.position;
      const normalAttribute = geometry.attributes.normal;

      const positions = Array.from(positionAttribute.array);
      const normals = Array.from(normalAttribute.array);

      deletedTrianglesList.forEach(({ vertices, normals: triNormals }) => {
        positions.push(...vertices);
        normals.push(...triNormals);
      });

      const newGeometry = new THREE.BufferGeometry();
      newGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(positions, 3)
      );
      newGeometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(normals, 3)
      );

      object.geometry.dispose();
      object.geometry = newGeometry;

      console.log(`Redo performed, restored triangles on model: ${objectId}`);
    },
    [modelRef]
  );

  const onMouseMove = useCallback(
    (e) => {
      if (e.target.closest(".ui-outside-scene")) {
        return;
      }

      const rect = containerRef.current.getBoundingClientRect();
      const mouse = new THREE.Vector2(
        ((e.clientX - rect.left) / rect.width) * 2 - 1,
        -((e.clientY - rect.top) / rect.height) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, cameraRef.current);
      const visibleObjects = modelRef.current.children.filter(
        (child) => child.visible
      );
      const intersects = raycaster.intersectObjects(visibleObjects, true);

      if (intersects.length > 0) {
        const intersect = intersects[0];
        drillBrushRef.current.position.copy(intersect.point);
        drillBrushRef.current.lookAt(
          intersect.point.clone().add(intersect.face.normal)
        ); // Make brush follow surface normal
        setBrushActive(true);
      } else {
        setBrushActive(false);
      }
    },
    [cameraRef, containerRef, modelRef]
  );

  const performDrillAuto = useCallback(
    (modelName, intersectPoint, brushSize) => {
      console.log("Performing drill operation on model:", modelName);
      if (!modelRef.current || !sceneRef.current) {
        console.error("Model or scene reference not found.");
        return;
      }

      const targetModel = modelRef.current.children.find(
        (child) => child.name === modelName
      );

      console.log(targetModel);

      if (!targetModel) {
        console.error(`Model with name "${modelName}" not found.`);
        return;
      }

      const geometry = targetModel.geometry;
      const positionAttribute = geometry.attributes.position;
      const normalAttribute = geometry.attributes.normal;

      if (!positionAttribute || !normalAttribute) {
        console.error("Geometry attributes missing.");
        return;
      }

      const positions = positionAttribute.array;
      const normals = normalAttribute.array;

      const verticesToRemove = new Set();

      for (let i = 0; i < positions.length; i += 9) {
        const v1 = new THREE.Vector3(
          positions[i],
          positions[i + 1],
          positions[i + 2]
        );
        const v2 = new THREE.Vector3(
          positions[i + 3],
          positions[i + 4],
          positions[i + 5]
        );
        const v3 = new THREE.Vector3(
          positions[i + 6],
          positions[i + 7],
          positions[i + 8]
        );

        const center = new THREE.Vector3()
          .addVectors(v1, v2)
          .add(v3)
          .divideScalar(3);

        if (center.distanceTo(intersectPoint) < brushSize) {
          verticesToRemove.add(i);
          verticesToRemove.add(i + 1);
          verticesToRemove.add(i + 2);
          verticesToRemove.add(i + 3);
          verticesToRemove.add(i + 4);
          verticesToRemove.add(i + 5);
          verticesToRemove.add(i + 6);
          verticesToRemove.add(i + 7);
          verticesToRemove.add(i + 8);
        }
      }

      const newPositions = [];
      const newNormals = [];

      for (let i = 0; i < positions.length; i += 3) {
        if (!verticesToRemove.has(i)) {
          newPositions.push(positions[i], positions[i + 1], positions[i + 2]);
          newNormals.push(normals[i], normals[i + 1], normals[i + 2]);
        }
      }

      const newGeometry = new THREE.BufferGeometry();
      newGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(newPositions, 3)
      );
      newGeometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(newNormals, 3)
      );

      targetModel.geometry.dispose();
      targetModel.geometry = newGeometry;

      console.log(`Drill operation performed on model: ${modelName}`);
    },
    [modelRef, sceneRef]
  );

  const onMouseDown = useCallback(
    (e) => {
      if (e.button !== 0 || e.target.closest(".ui-outside-scene")) {
        return;
      }
      if (!brushActive) return;

      const rect = containerRef.current.getBoundingClientRect();
      const mouse = new THREE.Vector2(
        ((e.clientX - rect.left) / rect.width) * 2 - 1,
        -((e.clientY - rect.top) / rect.height) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, cameraRef.current);
      const visibleObjects = modelRef.current.children.filter(
        (child) => child.visible
      );
      const intersects = raycaster.intersectObjects(visibleObjects, true);

      if (intersects.length > 0) {
        performDrill(intersects[0]);
      }
    },
    [brushActive, cameraRef, containerRef, modelRef, performDrill]
  );

  useEffect(() => {
    if (isDrillMode) {
      const brush = initializeBrush(brushSize);
      if (drillBrushRef.current) {
        sceneRef.current.remove(drillBrushRef.current);
      }
      sceneRef.current.add(brush);
      drillBrushRef.current = brush;

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mousedown", onMouseDown);

      return () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mousedown", onMouseDown);
      };
    } else {
      if (drillBrushRef.current) {
        sceneRef.current.remove(drillBrushRef.current);
        drillBrushRef.current = null;
      }
    }
  }, [
    isDrillMode,
    brushSize,
    initializeBrush,
    onMouseMove,
    onMouseDown,
    sceneRef,
  ]);

  return {
    isDrillMode,
    setIsDrillMode,
    brushSize,
    setBrushSize,
    undoDrill,
    performDrillAuto,
  };
};

export default useDrillMode;
