import Navbar from "../components/common/Navbar";
import AddOrEditCasePageView from "../components/addcase/AddOrEditCasePageView";

const AddOrEditCasePage = () => {
  return (
    <>
      <Navbar />
      <AddOrEditCasePageView />
    </>
  );
};

export default AddOrEditCasePage;
