import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ref, push, update, remove, child } from "firebase/database";
import { database } from "../firebase";
import * as THREE from "three";
import { updateAnnotations } from "../redux/casesRedux";

const useAnnotations = (
  sceneRef,
  cameraRef,
  containerRef,
  controlsRef,
  caseId,
  annotations,
  undoStack,
  undoIndex
) => {
  const [pins, setPins] = useState(annotations);
  const [tempAnnotationDetails, setTempAnnotationDetails] = useState(null);
  const [isAnnotationModalOpen, setIsAnnotationModalOpen] = useState(false);
  const [isAnnotationMode, setIsAnnotationMode] = useState(false);

  const dispatch = useDispatch();

  const createPin = useCallback(
    (position) => {
      if (!sceneRef.current) {
        // TODO Not sure if this is good or not.
        setTimeout(() => {
          createPin(position);
        }, 1000);
        console.warn("Scene is not ready yet.");
        return null;
      }

      const geometry = new THREE.SphereGeometry(1, 32, 32);
      const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      const pin = new THREE.Mesh(geometry, material);

      pin.position.set(position.x, position.y, position.z);
      sceneRef.current.add(pin);

      return pin; // Return the pin if creation was successful
    },
    [sceneRef]
  );

  const handleAnnotationClick = useCallback(
    (event) => {
      if (tempAnnotationDetails) return;

      event.preventDefault();

      const container = containerRef.current;
      const rect = container.getBoundingClientRect();

      const mouse = new THREE.Vector2(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, cameraRef.current);

      const visibleModels = sceneRef.current.children.filter(
        (child) => child.visible
      );

      if (visibleModels.length > 0) {
        const intersects = raycaster.intersectObjects(visibleModels, true);

        if (intersects.length > 0) {
          const point = intersects[0].point;
          setTempAnnotationDetails({ x: point.x, y: point.y, z: point.z });
          setIsAnnotationModalOpen(true);
        }
      }
    },
    [cameraRef, containerRef, sceneRef, tempAnnotationDetails]
  );

  //TODO Read this and compare the logic. GPT had a quick fix and you did not have enough time to investigate.
  const handleSaveAnnotation = useCallback(
    async (
      annotationText,
      skipUndoStack = true,
      position = null,
      existingAnnotationId = null // When provided, we re‑save with the same ID.
    ) => {
      const annotationPosition = position || tempAnnotationDetails;
      if (!annotationPosition) return;

      // Create a new pin mesh (always create a new mesh for rendering).
      const pinMesh = createPin(annotationPosition);

      // Use the provided annotation ID (for re‑saving) or generate a new one.
      const annotationId =
        existingAnnotationId ||
        push(child(ref(database), `cases/${caseId}/items/annotations`)).key;

      const newPin = {
        coordinates: annotationPosition,
        annotation: annotationText,
      };

      // Update (or create) the annotation in Firebase using the determined annotationId.
      await update(
        ref(database, `cases/${caseId}/items/annotations/${annotationId}`),
        newPin
      );

      // Update local state: if re‑saving, remove any duplicate (old) pin first.
      setPins((prevPins) => {
        const otherPins = prevPins.filter((pin) => pin.id !== annotationId);
        return [
          ...otherPins,
          { ...newPin, id: annotationId, mesh: pinMesh, show: true },
        ];
      });
      setIsAnnotationModalOpen(false);
      setTempAnnotationDetails(null);

      // Dispatch the Redux update.
      dispatch(
        updateAnnotations({
          actionType: "add",
          annotationId,
          annotationData: newPin,
        })
      );

      // If we're not in undo/redo mode, push an undo action.
      if (skipUndoStack) {
        const annotationUndo = {
          type: "annotation",
          annotationId,
          annotationData: newPin,
          action: "save",
        };

        undoStack.current = undoStack.current.slice(0, undoIndex.current);
        undoStack.current.push(annotationUndo);
        undoIndex.current += 1;
      }
    },
    [caseId, createPin, dispatch, tempAnnotationDetails, undoStack, undoIndex]
  );

  const handleDeleteAnnotation = useCallback(
    (index, annotationId, skipUndoStack = true) => {
      const targetPin = pins[index];

      if (targetPin && targetPin.mesh) {
        targetPin.mesh.geometry.dispose();
        targetPin.mesh.material.dispose();
        sceneRef.current.remove(targetPin.mesh);
      }

      const updatedPins = [...pins];
      updatedPins.splice(index, 1);
      setPins(updatedPins);
      console.log("Deleting annotation", annotationId);

      remove(
        ref(database, `cases/${caseId}/items/annotations/${annotationId}`)
      );

      dispatch(
        updateAnnotations({
          actionType: "delete",
          annotationId,
        })
      );

      if (skipUndoStack) {
        const deleteUndo = {
          type: "annotation",
          annotationId: annotationId,
          annotationData: targetPin,
          action: "delete",
        };

        undoStack.current = undoStack.current.slice(0, undoIndex.current);
        undoStack.current.push(deleteUndo);
        undoIndex.current += 1;
      }
    },
    [caseId, dispatch, pins, sceneRef, undoStack, undoIndex]
  );

  // TODO Add this to undo redo as well. But this one is tricky since the ids change. Modify the others accordingly. If this one is needed for the undo as well.
  const handleSaveAnnotationText = useCallback(
    (annotationText, annotationId) => {
      update(
        ref(database, `cases/${caseId}/items/annotations/${annotationId}`),
        {
          annotation: annotationText,
        }
      );

      setPins((prevPins) =>
        prevPins.map((pin) =>
          pin.id === annotationId ? { ...pin, annotation: annotationText } : pin
        )
      );

      dispatch(
        updateAnnotations({
          actionType: "update",
          annotationId,
          annotationData: { annotation: annotationText },
        })
      );
    },
    [caseId, dispatch]
  );

  useEffect(() => {
    const currentContainer = containerRef.current;

    let clickStartPos = { x: null, y: null };

    const onMouseDown = (event) => {
      if (event.button !== 0) return;
      clickStartPos = { x: event.clientX, y: event.clientY };
    };

    const onMouseUp = (event) => {
      if (event.button !== 0) return;
      const clickEndPos = { x: event.clientX, y: event.clientY };
      const dx = clickEndPos.x - clickStartPos.x;
      const dy = clickEndPos.y - clickStartPos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      // Check if the click is on a UI element
      if (event.target.closest(".ui-outside-scene")) return;

      if (distance < 5 && isAnnotationMode) {
        handleAnnotationClick(event);
      }
    };

    if (currentContainer) {
      currentContainer.addEventListener("mousedown", onMouseDown);
      currentContainer.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener("mousedown", onMouseDown);
        currentContainer.removeEventListener("mouseup", onMouseUp);
      }
    };
  }, [
    isAnnotationMode,
    tempAnnotationDetails,
    handleAnnotationClick,
    containerRef,
  ]);

  useEffect(() => {
    const currentAnnotations = annotations;
    const timeoutId = setTimeout(() => {
      const newPins = currentAnnotations.map((annotation) => {
        const position = new THREE.Vector3(
          annotation.coordinates.x,
          annotation.coordinates.y,
          annotation.coordinates.z
        );

        const pinMesh = createPin(position);
        return { ...annotation, mesh: pinMesh };
      });
      setPins(newPins);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [annotations, createPin]);

  useEffect(() => {
    const updatePinsPosition = () => {
      pins.forEach((pin, index) => {
        // Get the position of the pin in world coordinates
        const worldPosition = new THREE.Vector3(
          pin.coordinates.x,
          pin.coordinates.y,
          pin.coordinates.z
        );

        // Project the world coordinates to screen coordinates
        const screenPosition = worldPosition.project(cameraRef.current);

        // Convert screen coordinates to CSS pixels
        const x =
          ((screenPosition.x + 1) / 2) * containerRef.current.clientWidth;
        const y =
          ((-screenPosition.y + 1) / 2) * containerRef.current.clientHeight;

        // Update the position of the pin if the element exists
        const pinElement = document.getElementById(`pin-${index}`);
        if (pinElement) {
          pinElement.style.top = `${y}px`;
          pinElement.style.left = `${x}px`;
        }
      });
    };

    const currentContainer = containerRef.current;
    const currentControls = controlsRef.current; // Save the current value of the ref

    if (currentContainer && cameraRef.current && currentControls) {
      updatePinsPosition();

      // Add event listeners for changes in viewport size or camera movement
      window.addEventListener("resize", updatePinsPosition);
      currentControls.addEventListener("change", updatePinsPosition);

      return () => {
        // Cleanup: remove event listeners
        window.removeEventListener("resize", updatePinsPosition);
        currentControls.removeEventListener("change", updatePinsPosition);
      };
    }
  }, [pins, cameraRef, containerRef, controlsRef]);

  return {
    pins,
    tempAnnotationDetails,
    isAnnotationModalOpen,
    isAnnotationMode,
    setIsAnnotationMode,
    setIsAnnotationModalOpen,
    setTempAnnotationDetails,
    handleSaveAnnotation,
    handleDeleteAnnotation,
    handleSaveAnnotationText,
    setPins,
  };
};

export default useAnnotations;
