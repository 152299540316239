import { useCallback, useState } from "react";
import { ref, update, push, remove } from "firebase/database";
import { database } from "../firebase";
import { useDispatch } from "react-redux";
import { updateSurgeryPos } from "../redux/casesRedux";

const useSurgeryPos = (
  cameraRef,
  controlsRef,
  undoStack,
  undoIndex,
  selectedCase,
  performDrillAuto,
  performScalpelAutoMultiple
) => {
  const [isSurgeryPosMode, setIsSurgeryPosMode] = useState(false);
  const [selectedSurgeryPosId, setSelectedSurgeryPosId] = useState(null);
  const [isAddSurgeryPosModalOpen, setIsAddSurgeryPosModalOpen] =
    useState(false);
  const dispatch = useDispatch();

  const goToSurgeryPosition = useCallback(
    (posId) => {
      console.log("Go to Surgery Position triggered with id:", posId);

      // Retrieve all saved surgery positions.
      const surgeryPositions = selectedCase.items.surgeryPos;
      if (!surgeryPositions) {
        console.warn("No surgery positions saved.");
        return;
      }

      // Check if the provided surgery position id exists.
      if (!posId || !surgeryPositions[posId]) {
        console.warn("No surgery position selected or it doesn't exist.");
        return;
      }

      // Get the specific surgery position data.
      const surgeryPos = surgeryPositions[posId];
      const { actions, cameraPos } = surgeryPos;

      console.log("Restoring surgery position:", surgeryPos);

      // Execute the saved actions in order.
      if (actions && Array.isArray(actions)) {
        actions.forEach((action) => {
          if (action.type === "drill") {
            // Call performDrillAuto with the stored parameters.
            performDrillAuto(
              action.geometryId,
              action.intersectPoint,
              action.brushSize
            );
          } else if (action.type === "scalpel") {
            // Call performScalpelAutoMultiple with the stored parameters.
            performScalpelAutoMultiple(action);
          }
        });
      }

      // Clear the undo stack and reset the undo index.
      undoStack.current = [];
      undoIndex.current = 0;

      // Set the camera state using the saved cameraPos.
      if (cameraPos) {
        cameraRef.current.position.set(
          cameraPos.position.x,
          cameraPos.position.y,
          cameraPos.position.z
        );
        controlsRef.current.target.set(
          cameraPos.target.x,
          cameraPos.target.y,
          cameraPos.target.z
        );
        cameraRef.current.quaternion.set(
          cameraPos.quaternion.x,
          cameraPos.quaternion.y,
          cameraPos.quaternion.z,
          cameraPos.quaternion.w
        );
        cameraRef.current.up.set(
          cameraPos.up.x,
          cameraPos.up.y,
          cameraPos.up.z
        );
        cameraRef.current.lookAt(controlsRef.current.target);
        controlsRef.current.update();
      } else {
        console.warn("No cameraPos saved in the selected surgery position.");
      }
    },
    [
      selectedCase,
      performDrillAuto,
      performScalpelAutoMultiple,
      undoStack,
      undoIndex,
      cameraRef,
      controlsRef,
    ]
  );

  const saveSurgeryPosition = useCallback(
    (posId) => {
      console.log("Save Surgery Position triggered with id:", posId);

      // Ensure that a surgery position id is provided.
      if (!posId) {
        console.warn(
          "No surgery position selected. Please add or select one before saving."
        );
        return;
      }

      // Get all actions from the undo stack up to the current undo index.
      const actions = undoStack.current.slice(0, undoIndex.current);

      // Filter for drill and scalpel actions and map them to a serializable format.
      const actionsToSave = actions
        .filter(
          (action) => action.type === "drill" || action.type === "scalpel"
        )
        .map((action) => {
          if (action.type === "drill") {
            return {
              type: "drill",
              geometryId: action.geometryId,
              intersectPoint: {
                x: action.intersectPoint.x,
                y: action.intersectPoint.y,
                z: action.intersectPoint.z,
              },
              brushSize: action.brushSize,
            };
          } else if (action.type === "scalpel") {
            return {
              type: "scalpel",
              meshes: action.meshes,
              drawnShape: action.drawnShape.map((pt) => ({
                x: pt.x,
                y: pt.y,
                z: pt.z,
              })),
              cutHeight: action.cutHeight,
              normal: {
                x: action.normal.x,
                y: action.normal.y,
                z: action.normal.z,
              },
            };
          }
          return null;
        })
        .filter((action) => action !== null);

      // Capture the current camera state.
      const currentPosition = cameraRef.current.position.clone();
      const currentTarget = controlsRef.current.target.clone();
      const currentQuaternion = cameraRef.current.quaternion.clone();
      const currentUp = cameraRef.current.up.clone();

      // Convert THREE.Vector3/Quaternion values to plain objects for Firebase.
      const cameraPos = {
        position: {
          x: currentPosition.x,
          y: currentPosition.y,
          z: currentPosition.z,
        },
        target: {
          x: currentTarget.x,
          y: currentTarget.y,
          z: currentTarget.z,
        },
        quaternion: {
          x: currentQuaternion.x,
          y: currentQuaternion.y,
          z: currentQuaternion.z,
          w: currentQuaternion.w,
        },
        up: {
          x: currentUp.x,
          y: currentUp.y,
          z: currentUp.z,
        },
      };

      // Retrieve the current surgery positions object from the case.
      const currentSurgeryPos = selectedCase.items.surgeryPos || {};
      // Retrieve any existing actions for the selected surgery position.
      const existingActions =
        (currentSurgeryPos[posId] && currentSurgeryPos[posId].actions) || [];
      const newActions = [...existingActions, ...actionsToSave];

      // Prepare the updated surgery position data.
      const surgeryPosData = {
        actions: newActions,
        cameraPos,
      };

      // Reference to the Firebase location for the specific surgery position.
      const surgeryPosRef = ref(
        database,
        `cases/${selectedCase.id}/items/surgeryPos/${posId}`
      );

      // Update Firebase with the new data for the selected surgery position.
      update(surgeryPosRef, surgeryPosData)
        .then(() => {
          console.log("Surgery position saved successfully.");
          // Merge the updated data into the existing surgery positions object.
          const updatedSurgeryPos = {
            ...currentSurgeryPos,
            [posId]: {
              ...currentSurgeryPos[posId],
              ...surgeryPosData,
            },
          };
          // Dispatch the updated surgery positions to Redux.
          dispatch(updateSurgeryPos({ surgeryPos: updatedSurgeryPos }));
        })
        .catch((error) => {
          console.error("Error saving surgery position:", error);
        });
    },
    [undoStack, undoIndex, selectedCase, dispatch, cameraRef, controlsRef]
  );

  const addSurgeryPos = useCallback(
    (name) => {
      const currentSurgeryPos = selectedCase.items.surgeryPos || {};
      if (Object.keys(currentSurgeryPos).length >= 5) {
        console.warn("Maximum surgery positions reached.");
        return;
      }
      // Create a new unique surgery position id.
      const newSurgeryPosId = push(
        ref(database, `cases/${selectedCase.id}/items/surgeryPos`)
      ).key;
      // Initialize with default data including the name field.
      const defaultSurgeryPosData = {
        name, // Store the provided name.
        actions: [],
        cameraPos: null,
      };
      const surgeryPosRef = ref(
        database,
        `cases/${selectedCase.id}/items/surgeryPos/${newSurgeryPosId}`
      );
      update(surgeryPosRef, defaultSurgeryPosData)
        .then(() => {
          console.log("New surgery position created:", newSurgeryPosId);
          // Set this new surgery position as the selected one.
          setSelectedSurgeryPosId(newSurgeryPosId);
          // Create an updated surgery positions object.
          const updatedSurgeryPos = {
            ...currentSurgeryPos,
            [newSurgeryPosId]: defaultSurgeryPosData,
          };
          // Dispatch the updated surgery positions to Redux.
          dispatch(updateSurgeryPos({ surgeryPos: updatedSurgeryPos }));
        })
        .catch((err) => console.error("Error adding surgery position:", err));

      setIsAddSurgeryPosModalOpen(false);
    },
    [selectedCase, dispatch]
  );

  const deleteSurgeryPos = useCallback(
    (surgeryPosId) => {
      const surgeryPosRef = ref(
        database,
        `cases/${selectedCase.id}/items/surgeryPos/${surgeryPosId}`
      );
      remove(surgeryPosRef)
        .then(() => {
          console.log("Deleted surgery position:", surgeryPosId);
          // If the deleted surgery position was selected, clear the selection.
          setSelectedSurgeryPosId((prevId) =>
            prevId === surgeryPosId ? null : prevId
          );
          // Remove the deleted surgery position from the current data.
          const currentSurgeryPos = selectedCase.items.surgeryPos || {};
          const updatedSurgeryPos = { ...currentSurgeryPos };
          delete updatedSurgeryPos[surgeryPosId];
          // Dispatch the updated surgery positions to Redux.
          dispatch(updateSurgeryPos({ surgeryPos: updatedSurgeryPos }));
        })
        .catch((err) => console.error("Error deleting surgery position:", err));
    },
    [selectedCase, dispatch]
  );
  return {
    goToSurgeryPosition,
    saveSurgeryPosition,
    isSurgeryPosMode,
    setIsSurgeryPosMode,
    addSurgeryPos,
    deleteSurgeryPos,
    selectedSurgeryPosId,
    setSelectedSurgeryPosId,
    isAddSurgeryPosModalOpen,
    setIsAddSurgeryPosModalOpen,
  };
};

export default useSurgeryPos;
