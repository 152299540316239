import React from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Roles } from "../../constants/roles";

const DicomFiles = ({ dicomInfos, handleDownload, isMobile, userRole }) => {
  const isViewer = userRole === Roles.VIEWER;

  // Mobile version rendering
  if (isMobile) {
    return (
      <div className="space-y-4">
        <h2 className="text-lg font-semibold mb-4">DICOM Files</h2>
        {dicomInfos.map((file, index) => (
          <div key={index} className="border rounded-lg p-4 shadow-md">
            <div className="flex justify-between">
              <p className="font-semibold">Name:</p>
              <p>{file.name}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Uploaded By:</p>
              <p>{file.uploadedBy}</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold">Upload Date:</p>
              <p>{file.uploadDate}</p>
            </div>
            {/* Actions: Only download button */}
            <div className="flex justify-end mt-2">
              <button
                className="px-2 py-1 rounded hover:text-blue-500"
                onClick={() => handleDownload(file)}
              >
                <FileDownloadOutlinedIcon />
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Desktop/tablet version rendering
  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">DICOM Files</h2>
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2 text-center border-l-2 border-l-white">Name</th>
            <th className="p-2 text-center border-l-2 border-l-white">
              Uploaded By
            </th>
            <th className="p-2 text-center border-l-2 border-l-white">
              Upload Date
            </th>
            {!isViewer && (
              <th className="p-2 text-center border-l-2 border-l-white">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {dicomInfos.map((file, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
            >
              <td className="p-2 text-center">{file.name}</td>
              <td className="p-2 text-center">{file.uploadedBy}</td>
              <td className="p-2 text-center">{file.uploadDate}</td>
              {!isViewer && (
                <td className="p-2 text-center">
                  <button
                    className="px-2 py-1 rounded hover:text-blue-500"
                    onClick={() => handleDownload(file)}
                  >
                    <FileDownloadOutlinedIcon />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DicomFiles;
