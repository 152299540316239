import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DropDownMenu from "./DropDownMenu";
import useIsMobile from "../../hooks/useIsMobile";

const Navbar = () => {
  const navigate = useNavigate();
  const isSignedIn = useSelector((state) => state.user.isSignedIn);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useIsMobile();
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  const renderButtons = () => {
    if (isSignedIn) {
      return (
        <button
          className={`flex items-center justify-center hover:text-sky-400 text-slate-200 shadow rounded-md text-sm font-medium cursor-pointer ${
            isMobile ? "h-10" : "h-10 px-3"
          }`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <AccountCircleIcon className="ml-2" />
          <div className={`${isMobile ? "pr-2 w-24" : "px-3 py-2"}`}>
            {currentUser?.firstName} {currentUser?.lastName}
          </div>
        </button>
      );
    }
    return (
      <>
        <button
          className={`rounded-md text-sm font-medium shadow hover:text-sky-400 text-slate-200 ${
            isMobile ? "px-2 py-1" : "px-4 py-2"
          }`}
          onClick={() => navigate("/login")}
        >
          Login
        </button>
        <button
          className={`rounded-md text-sm font-medium shadow hover:text-sky-400 text-slate-200 ${
            isMobile ? "px-2 py-1" : "px-4 py-2"
          }`}
          onClick={() => navigate("/signup")}
        >
          Sign Up
        </button>
      </>
    );
  };

  return (
    <div className="relative" ref={menuRef}>
      <nav className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600">
        <div
          className={`flex items-center justify-between h-16 ${
            isMobile ? "px-4" : "px-16"
          }`}
        >
          <button
            onClick={() => navigate("/home")}
            className={`flex justify-center items-center space-x-2 text-slate-50 font-semibold hover:text-gray-200 transition duration-300 ${
              isMobile ? "text-sm" : "text-lg"
            }`}
          >
            <span>WELLZTECH PRE-OPERATIVE 3D PLANNING</span>
          </button>
          <div className="flex items-center space-x-4">{renderButtons()}</div>
        </div>
      </nav>
      <DropDownMenu isOpen={menuOpen} onClose={() => setMenuOpen(false)} />
    </div>
  );
};

export default Navbar;
