import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateModelInfos } from "../../redux/casesRedux";
import { useParams } from "react-router-dom";
import * as THREE from "three";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import TWEEN from "@tweenjs/tween.js";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ModelsSidebar from "./ModelsSidebar";
import AnnotationsSidebar from "./AnnotationsSidebar";
import MeasurementsSidebar from "./MeasurementsSidebar";
import AnnotationModal from "./modals/AnnotationModal";
import MeasurementModal from "./modals/MeasurementModal";
import AddSurgeryPosModal from "./modals/AddSurgeryPosModal";
import SurgeryPositionModal from "./modals/SurgeryPositionModal";
import Tabs from "./Tabs";
import useIsMobile from "../../hooks/useIsMobile";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { ref, update } from "firebase/database";
import { database } from "../../firebase";
import useDrillMode from "../../hooks/useDrillMode";
import useScalpelMode from "../../hooks/useScalpelMode";
import DrillModal from "./modals/DrillModal";
import DistanceMeasuringModal from "./modals/DistanceMeasuringModal";
import CameraOrientationModal from "./modals/CameraOrientationModal";
import ScalpelModal from "./modals/ScalpelModal";
import useFullScreen from "../../hooks/useFullScreen";
import useCamera from "../../hooks/useCamera";
import Pins from "./Pins";
import useMeasurement from "../../hooks/useMeasurement";
import useAnnotations from "../../hooks/useAnnotations";
import useSurgeryPos from "../../hooks/useSurgeryPos";

const ModelViewer = ({ stlURLs, modelColor, annotations, measurements }) => {
  const thisCase = useSelector((state) => state.cases.selectedCase);
  const dispatch = useDispatch();
  //TODO if this params take the argument from the url. This needs to be revisited since changing the a little bit url still shows the same model.
  const { id } = useParams();
  const containerRef = useRef(null);
  const modelRef = useRef(new THREE.Object3D());
  const controlsRef = useRef(null);
  const cameraRef = useRef(null);
  const sceneRef = useRef(null);
  const currentTweenRef = useRef(null);
  const undoStack = useRef([]);
  const undoIndex = useRef(0);
  const undoMeasurementStack = useRef([]);
  const color = useMemo(() => new THREE.Color(modelColor), [modelColor]);
  const [activeTab, setActiveTab] = useState("models");
  const [numOfLoadedModels, setNumOfLoadedModels] = useState(0);
  const [isModelLoading, setIsModelLoading] = useState(true);
  const [initialCameraPosition, setInitialCameraPosition] = useState(null);
  const [initialCameraTarget, setInitialCameraTarget] = useState(null);
  const [selectedModels, setSelectedModels] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const isMobile = useIsMobile();
  const [showOptions, setShowOptions] = useState(false);
  const {
    isDrillMode,
    setIsDrillMode,
    brushSize,
    setBrushSize,
    undoDrill,
    performDrillAuto,
  } = useDrillMode(
    sceneRef,
    cameraRef,
    modelRef,
    containerRef,
    undoStack,
    undoIndex
  );

  const {
    isScalpelMode,
    setIsScalpelMode,
    setDrawPoints,
    processing,
    setProcessing,
    cutHeight,
    setCutHeight,
    modifyGeometryAndAddToScene,
    clearDrawPoints,
    performScalpelAutoMultiple,
    undoScalpel,
  } = useScalpelMode(
    sceneRef,
    cameraRef,
    containerRef,
    modelRef,
    controlsRef,
    selectedModels,
    undoStack,
    undoIndex
  );
  const { isFullscreen, toggleFullScreen } = useFullScreen(containerRef);
  const {
    resetCamera,
    setHomeCameraView,
    goHomeCameraView,
    homeOrientation,
    isCameraOrientationMode,
    setIsCameraOrientationMode,
    isCameraRotationLocked,
    toggleCameraRotationLock,
    goTopView,
    goBottomView,
    goLeftView,
    goRightView,
    goFrontView,
    goBackView,
  } = useCamera(
    cameraRef,
    controlsRef,
    modelRef,
    initialCameraPosition,
    initialCameraTarget
  );

  const {
    pathPoints,
    totalDistance,
    savedMeasurements,
    isDistanceMeasuringMode,
    setIsDistanceMeasuringMode,
    closePolygon,
    clearMeasurements,
    handleSaveMeasurements,
    handleDeleteMeasurement,
    handleSaveMeasurementText,
    handleMeasurementShowAndHide,
    setIsMeasurementModalOpen,
    isMeasurementModalOpen,
  } = useMeasurement(
    sceneRef,
    cameraRef,
    modelRef,
    containerRef,
    id,
    measurements,
    undoStack,
    undoIndex,
    undoMeasurementStack
  );
  const {
    pins,
    tempAnnotationDetails,
    isAnnotationModalOpen,
    isAnnotationMode,
    setIsAnnotationMode,
    setIsAnnotationModalOpen,
    setTempAnnotationDetails,
    handleSaveAnnotation,
    handleDeleteAnnotation,
    handleSaveAnnotationText,
    setPins,
  } = useAnnotations(
    sceneRef,
    cameraRef,
    containerRef,
    controlsRef,
    id,
    annotations,
    undoStack,
    undoIndex
  );
  const {
    goToSurgeryPosition,
    saveSurgeryPosition,
    isSurgeryPosMode,
    setIsSurgeryPosMode,
    addSurgeryPos,
    deleteSurgeryPos,
    selectedSurgeryPosId,
    setSelectedSurgeryPosId,
    isAddSurgeryPosModalOpen,
    setIsAddSurgeryPosModalOpen,
  } = useSurgeryPos(
    cameraRef,
    controlsRef,
    undoStack,
    undoIndex,
    thisCase,
    performDrillAuto,
    performScalpelAutoMultiple
  );

  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
  }, [isMobile]);

  const handleUndo = useCallback(() => {
    if (undoIndex.current > 0) {
      const action = undoStack.current[undoIndex.current - 1];

      if (action && action.type === "drill") {
        undoDrill(action.geometryId, action.deletedTrianglesList);
      }

      if (action && action.type === "annotation") {
        if (action.action === "save") {
          // Undo a save by deleting the annotation.
          const pinIndex = pins.findIndex(
            (pin) => pin.id === action.annotationId
          );
          handleDeleteAnnotation(pinIndex, action.annotationId, false);
        }
        if (action.action === "delete") {
          // Undo a deletion by re‑saving the annotation with its original ID.
          handleSaveAnnotation(
            action.annotationData.annotation,
            false, // skip undo push
            action.annotationData.coordinates,
            action.annotationId // use the original annotation ID
          );
        }
      }

      if (action && action.type === "measurement") {
        if (action.action === "save") {
          // Undo a measurement save by deleting the measurement.
          const measurementIndex = savedMeasurements.findIndex(
            (m) => m.id === action.measurementId
          );
          if (measurementIndex !== -1) {
            handleDeleteMeasurement(measurementIndex, false);
          }
        }
        if (action.action === "delete") {
          // Undo a measurement deletion by restoring the measurement.
          // Here, pass the stored measurementData to re-save it.
          handleSaveMeasurements(null, action.measurementData, false);
        }
      }

      if (action && action.type === "scalpel") {
        // Undo the scalpel action by restoring the deleted triangles for each affected mesh.
        // The undoScalpel function iterates over action.geometries (a map of object names to deletedTrianglesList)
        // and appends the saved triangles back to each mesh’s geometry.
        undoScalpel(action);
      }

      undoIndex.current -= 1;
    }
  }, [
    undoStack,
    undoIndex,
    undoDrill,
    pins,
    handleDeleteAnnotation,
    handleSaveAnnotation,
    savedMeasurements,
    handleDeleteMeasurement,
    handleSaveMeasurements,
    undoScalpel,
  ]);

  const handleRedo = useCallback(() => {
    if (undoIndex.current < undoStack.current.length) {
      const action = undoStack.current[undoIndex.current];

      if (action && action.type === "drill") {
        performDrillAuto(
          action.geometryId,
          action.intersectPoint,
          action.brushSize
        );
      }

      if (action && action.type === "annotation") {
        if (action.action === "save") {
          // Redo a save by re‑saving the annotation using the original ID.
          handleSaveAnnotation(
            action.annotationData.annotation,
            false, // skip undo push
            action.annotationData.coordinates,
            action.annotationId // use the same original ID
          );
        }
        if (action.action === "delete") {
          // Redo a deletion by deleting the annotation.
          const pinIndex = pins.findIndex(
            (pin) => pin.id === action.annotationId
          );
          handleDeleteAnnotation(pinIndex, action.annotationId, false);
        }
      }

      if (action && action.type === "measurement") {
        if (action.action === "save") {
          // Redo a measurement save by re-saving (restoring) the measurement.
          handleSaveMeasurements(null, action.measurementData, false);
        }
        if (action.action === "delete") {
          // Redo a measurement deletion by deleting it again.
          const measurementIndex = savedMeasurements.findIndex(
            (m) => m.id === action.measurementId
          );
          if (measurementIndex !== -1) {
            handleDeleteMeasurement(measurementIndex, false);
          }
        }
      }

      if (action && action.type === "scalpel") {
        // Redo the scalpel action by re‑removing the triangles for each affected mesh.
        // The redoScalpel function uses the stored deletedTrianglesList for each mesh to remove
        // the triangles again from the geometry.
        performScalpelAutoMultiple(action);
      }
      undoIndex.current += 1;
    }
  }, [
    undoStack,
    undoIndex,
    performDrillAuto,
    pins,
    handleDeleteAnnotation,
    handleSaveAnnotation,
    savedMeasurements,
    handleDeleteMeasurement,
    handleSaveMeasurements,
    performScalpelAutoMultiple,
  ]);

  useEffect(() => {
    const handleUndoRedo = (event) => {
      if (event.ctrlKey) {
        if (event.key === "z") {
          event.preventDefault();
          handleUndo();
        } else if (event.key === "y") {
          event.preventDefault();
          handleRedo();
        }
      }
    };

    window.addEventListener("keydown", handleUndoRedo);
    return () => window.removeEventListener("keydown", handleUndoRedo);
  }, [handleUndo, handleRedo]);

  // Load Models
  useEffect(() => {
    const loadModels = () => {
      // Create the scene
      const scene = new THREE.Scene();
      sceneRef.current = scene;
      scene.add(modelRef.current);

      // Ambient light for soft overall illumination
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.3); // Low intensity
      scene.add(ambientLight);

      // Directional light 1
      const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight1.position.set(2, 2, 2); // Positioned diagonally above the model
      // directionalLight1.castShadow = true; // Enable shadows
      scene.add(directionalLight1);

      // Directional light 2
      const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight2.position.set(-2, -2, 2); // Positioned diagonally opposite to the key light
      scene.add(directionalLight2);

      // Directional light 3
      const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight3.position.set(0, 3, -2); // Positioned behind and above the model
      scene.add(directionalLight3);

      // Directional light 4
      const directionalLight4 = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight4.position.set(1, -1, 1); // Positioned behind and above the model
      scene.add(directionalLight4);

      const loader = new STLLoader();
      const promises = [];
      const tempModelRefs = Array(stlURLs.length).fill(null);
      let count = 0;
      stlURLs.forEach((stl, index) => {
        const url = stl.url;
        // Create a promise for each model load operation
        const promise = new Promise((resolve, reject) => {
          loader.load(
            url,
            (geometry) => {
              // Create the mesh
              const material = new THREE.MeshStandardMaterial({
                color: stl.color || 0xd3d3d3,
                transparent: true,
                opacity: 1,
                clippingPlanes: [],
              });
              const mesh = new THREE.Mesh(geometry, material);
              mesh.name = stl.name;
              mesh.renderOrder = stl.renderOrder;
              tempModelRefs[index] = mesh;
              count++;
              setNumOfLoadedModels(count);
              resolve();
            },
            undefined,
            reject
          );
        });
        promises.push(promise);
      });

      Promise.all(promises)
        .then(() => {
          tempModelRefs.forEach((mesh) => {
            if (mesh) modelRef.current.add(mesh);
          });

          setIsModelLoading(false);
          // Create a camera
          const camera = new THREE.PerspectiveCamera(
            50,
            containerRef.current.clientWidth /
              containerRef.current.clientHeight,
            0.1,
            10000
          );
          cameraRef.current = camera; // Store the camera reference

          // Create a renderer
          const renderer = new THREE.WebGLRenderer({ alpha: true });
          renderer.setSize(
            containerRef.current.clientWidth,
            containerRef.current.clientHeight
          );
          renderer.localClippingEnabled = true;
          renderer.setClearColor(0xbbbbbb);
          containerRef.current.appendChild(renderer.domElement);

          // Create controls
          const controls = new TrackballControls(
            cameraRef.current,
            renderer.domElement
          );
          controlsRef.current = controls;
          controls.rotateSpeed = 5.0;
          controls.zoomSpeed = 2;
          controls.panSpeed = 0.8;
          controls.staticMoving = true;
          controls.dynamicDampingFactor = 0.3;

          // Configure mouse buttons
          /* controls.mouseButtons = {
            LEFT: THREE.MOUSE.PAN,
            MIDDLE: THREE.MOUSE.ZOOM,
            RIGHT: THREE.MOUSE.ROTATE,
          }; */

          // Calculate the bounding box of the model
          const boundingBox = new THREE.Box3().setFromObject(modelRef.current);

          //space center calculations
          const center = new THREE.Vector3();
          boundingBox.getCenter(center);

          const size = boundingBox.getSize(new THREE.Vector3());
          const maxDimension = Math.max(size.x, size.y, size.z);
          const distance = maxDimension * 2;

          cameraRef.current.position.set(
            center.x,
            center.y,
            center.z + distance
          );
          controlsRef.current.target.set(center.x, center.y, center.z);

          // Set the initial camera position and target
          setInitialCameraPosition({
            x: cameraRef.current.position.x,
            y: cameraRef.current.position.y,
            z: cameraRef.current.position.z,
            initialQuaternion: cameraRef.current.quaternion.clone(),
            up: cameraRef.current.up.clone(),
          });

          setInitialCameraTarget({
            x: controlsRef.current.target.x,
            y: controlsRef.current.target.y,
            z: controlsRef.current.target.z,
          });

          // Render the scene
          function animate() {
            requestAnimationFrame(animate);
            TWEEN.update();
            controlsRef.current.update();
            renderer.render(sceneRef.current, cameraRef.current);
          }

          animate();

          // Update the renderer size when the window is resized
          const handleResize = () => {
            if (containerRef.current) {
              renderer.setSize(
                containerRef.current.clientWidth,
                containerRef.current.clientHeight
              );
              camera.aspect =
                containerRef.current.clientWidth /
                containerRef.current.clientHeight;
              camera.updateProjectionMatrix();
            }
          };
          window.addEventListener("resize", handleResize);

          // Clean up event listeners on component unmount
          return () => {
            clearTimeout(timer);
            window.removeEventListener("resize", handleResize);
            scene.children.forEach((child) => {
              if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                child.material.dispose();
              }
            });
            renderer.dispose();
          };
        })
        .catch((error) => {
          // Handle errors if any model fails to load
          console.error("Error loading models:", error);
        });
    };

    const timer = setTimeout(() => {
      if (numOfLoadedModels === 0) {
        loadModels();
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [color, stlURLs, numOfLoadedModels]);

  const handleCheckboxChange = (checked, id) => {
    setPins((prevPins) => {
      return prevPins.map((pin) => {
        if (pin.id === id) {
          return { ...pin, show: checked };
        }
        return pin;
      });
    });
  };

  const handleMoveToStructure = (index) => {
    const thisModel = modelRef.current.children[index];

    // Compute the bounding box for the model
    thisModel.geometry.computeBoundingBox();
    const boundingBox = thisModel.geometry.boundingBox;

    // Calculate the center of the bounding box
    const thisModelCenter = new THREE.Vector3();
    boundingBox.getCenter(thisModelCenter);
    thisModel.localToWorld(thisModelCenter); // Convert local center to world coordinates

    // Call the `moveToAnnotation` function with the center as the target position
    moveToAnnotation(thisModelCenter);
  };

  const handleOpacityChange = (index, opacity) => {
    if (
      sceneRef.current &&
      modelRef.current &&
      modelRef.current.children[index]
    ) {
      modelRef.current.children[index].material.opacity = opacity;
      modelRef.current.children[index].material.needsUpdate = true;
    }
  };

  const handleVisibilityChange = (index, value) => {
    if (
      sceneRef.current &&
      modelRef.current &&
      modelRef.current.children[index]
    ) {
      modelRef.current.children[index].visible = value;
    }
  };

  const handleColorChange = (index, color) => {
    if (
      sceneRef.current &&
      modelRef.current &&
      modelRef.current.children[index]
    ) {
      modelRef.current.children[index].material.color.set(color);
    }
  };

  const handleColorSave = (index, color) => {
    const modelDbRef = ref(database, `cases/${id}/items/modelInfos/${index}`);
    update(modelDbRef, { color });

    // Redux store'da güncelleme
    const updatedModelInfos = [...thisCase.items.modelInfos];
    updatedModelInfos[index] = {
      ...updatedModelInfos[index],
      color,
    };

    dispatch(
      updateModelInfos({
        caseId: id,
        modelInfos: updatedModelInfos,
      })
    );
  };

  const calculateOptimalCameraPosition = (annotationPosition) => {
    const boundingBox = new THREE.Box3().setFromObject(modelRef.current);
    const center = boundingBox.getCenter(new THREE.Vector3());
    const size = boundingBox.getSize(new THREE.Vector3());
    const maxDimension = Math.max(size.x, size.y, size.z);

    // Calculate the distance from the annotation based on the model size
    const distance = maxDimension;
    const height = maxDimension;

    // Calculate new camera position
    const direction = new THREE.Vector3()
      .subVectors(annotationPosition, center)
      .normalize();
    const newPosition = new THREE.Vector3().addVectors(
      annotationPosition,
      direction.multiplyScalar(distance)
    );
    newPosition.y += height; // Adjust height to look from above

    return newPosition;
  };

  //TODO Adding this ti useAnnitations hook complicates stuff. It breaks some ref somehow and animation does not work as intented.
  function moveToAnnotation(annotationPosition) {
    if (!sceneRef.current || !modelRef.current) {
      console.error("Scene or model is not ready.");
      return;
    }

    /*     if (currentTweenRef.current) {
      currentTweenRef.current.stop();
    } */

    // Calculate optimal positions for both the camera and its target based on the annotation.
    const optimalCameraPosition =
      calculateOptimalCameraPosition(annotationPosition);
    const cameraTargetPosition = annotationPosition; // Directly focusing on the annotation.

    const initialCameraPosition = {
      x: cameraRef.current.position.x,
      y: cameraRef.current.position.y,
      z: cameraRef.current.position.z,
    };

    const initialTargetPosition = {
      x: controlsRef.current.target.x,
      y: controlsRef.current.target.y,
      z: controlsRef.current.target.z,
    };

    // Interpolating camera position
    const cameraTween = new TWEEN.Tween(initialCameraPosition)
      .to(
        {
          x: optimalCameraPosition.x,
          y: optimalCameraPosition.y,
          z: optimalCameraPosition.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(({ x, y, z }) => cameraRef.current.position.set(x, y, z))
      .start();

    // Interpolating camera target
    const targetTween = new TWEEN.Tween(initialTargetPosition)
      .to(
        {
          x: cameraTargetPosition.x,
          y: cameraTargetPosition.y,
          z: cameraTargetPosition.z,
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(({ x, y, z }) => controlsRef.current.target.set(x, y, z))
      .onComplete(() => {
        controlsRef.current.update();
        currentTweenRef.current = null; // Ensure to clear the reference once animation completes
      })
      .start();

    // Keep a reference to the current tween for potential stopping
    currentTweenRef.current = { cameraTween, targetTween };
  }

  const handleCancelAnnotation = () => {
    setIsAnnotationModalOpen(false);
    setTempAnnotationDetails(null);
  };

  const toggleAnnotationMode = () => {
    if (!isAnnotationMode) {
      setIsDistanceMeasuringMode(false);
      setIsScalpelMode(false);
      setShowOptions(false);
      clearDrawPoints();
      setIsCameraOrientationMode(false);
      clearMeasurements();
      setIsDrillMode(false);
      setIsSurgeryPosMode(false);
      setIsAnnotationMode(true);
      setActiveTab("annotations");
    } else {
      setIsAnnotationMode(false);
    }
  };

  const toggleDistanceMeasuringMode = () => {
    if (!isDistanceMeasuringMode) {
      setIsAnnotationMode(false);
      setIsScalpelMode(false);
      setShowOptions(false);
      setIsCameraOrientationMode(false);
      clearDrawPoints();
      setIsDrillMode(false);
      setIsSurgeryPosMode(false);
      setIsDistanceMeasuringMode(true);
      setActiveTab("measurements");
    } else {
      setIsDistanceMeasuringMode(false);
      clearMeasurements();
    }
  };

  const toggleScalpelMode = () => {
    if (!isScalpelMode) {
      setIsAnnotationMode(false);
      setIsDistanceMeasuringMode(false);
      setIsCameraOrientationMode(false);
      setIsSurgeryPosMode(false);
      clearMeasurements();
      setIsDrillMode(false);
      setIsScalpelMode(true);
      setActiveTab("models");
      setShowOptions(true);
      setDrawPoints([]);
    } else {
      setIsScalpelMode(false);
      setShowOptions(false);
      clearDrawPoints();
    }
  };

  const toggleDrillMode = () => {
    if (!isDrillMode) {
      setIsAnnotationMode(false);
      setIsDistanceMeasuringMode(false);
      setIsScalpelMode(false);
      setIsCameraOrientationMode(false);
      setIsSurgeryPosMode(false);
      setShowOptions(false);
      clearDrawPoints();
      clearMeasurements();
      setIsDrillMode(true);
      setActiveTab("models");
    } else {
      setIsDrillMode(false);
      clearDrawPoints();
    }
  };

  const toggleCameraOrientationMode = () => {
    if (!isCameraOrientationMode) {
      setIsAnnotationMode(false);
      setIsDistanceMeasuringMode(false);
      setIsScalpelMode(false);
      setIsDrillMode(false);
      setIsSurgeryPosMode(false);
      setIsCameraOrientationMode(true);
    } else {
      setIsCameraOrientationMode(false);
    }
  };

  const toggleSurgeryPosMode = () => {
    if (!isSurgeryPosMode) {
      setIsAnnotationMode(false);
      setIsDistanceMeasuringMode(false);
      setIsScalpelMode(false);
      setIsDrillMode(false);
      setIsCameraOrientationMode(false);
      setIsSurgeryPosMode(true);
    } else {
      setIsSurgeryPosMode(false);
    }
  };

  return (
    <div className="relative">
      <div className="relative h-[calc(100vh-128px)] overflow-hidden bg-gray-50">
        <div className="h-screen w-screen z-0" ref={containerRef}>
          {containerRef.current && cameraRef.current && (
            <Pins
              pins={pins}
              cameraRef={cameraRef}
              containerRef={containerRef}
              moveToAnnotation={moveToAnnotation}
            />
          )}
          {/* Tabs for sidebar navigation */}
          {isSidebarOpen && (
            <div className="ui-outside-scene">
              <Tabs
                activeTab={activeTab}
                onTabClick={setActiveTab}
                setIsSidebarOpen={setIsSidebarOpen}
              />
            </div>
          )}
          {/* Mini Button to Open Sidebar */}
          {!isSidebarOpen && (
            <button
              onClick={() => setIsSidebarOpen(true)}
              className="absolute top-8 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-l ui-outside-scene"
            >
              <ArrowBackIosNewRoundedIcon />
            </button>
          )}
          {/* Conditionally render the appropriate sidebar */}
          {!isModelLoading && isSidebarOpen && (
            <div className="ui-outside-scene">
              {activeTab === "models" && (
                <ModelsSidebar
                  modelsRefs={modelRef.current.children}
                  onOpacityChange={handleOpacityChange}
                  onVisibilityChange={handleVisibilityChange}
                  onColorChange={handleColorChange}
                  onColorSave={handleColorSave}
                  onMoveToStructure={handleMoveToStructure}
                />
              )}
              {activeTab === "annotations" && (
                <AnnotationsSidebar
                  pins={pins}
                  onDelete={handleDeleteAnnotation}
                  onSave={handleSaveAnnotationText}
                  handleCheckboxChange={handleCheckboxChange}
                  onSelect={(pin) => {
                    moveToAnnotation(
                      new THREE.Vector3(
                        pin.coordinates.x,
                        pin.coordinates.y,
                        pin.coordinates.z
                      )
                    );
                  }}
                />
              )}
              {activeTab === "measurements" && (
                <MeasurementsSidebar
                  totalDistance={totalDistance}
                  measurements={savedMeasurements}
                  onDelete={handleDeleteMeasurement}
                  onSave={handleSaveMeasurementText}
                  handleCheckboxChange={handleMeasurementShowAndHide}
                />
              )}
            </div>
          )}
          {/* Controls Container */}
          {/* TODO Making this as another component corrupts its positioning?? */}
          <div className="absolute bottom-2 md:bottom-32 left-0 w-full p-2 text-blue-500 z-10 text-sm ui-outside-scene">
            <div
              className={`flex items-center overflow-x-auto flex-nowrap space-x-4 ${
                isSidebarOpen ? "w-3/5" : "w-full"
              }`}
            >
              {/* Independent Controls */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleAnnotationMode();
                }}
                className={`py-1 px-2 rounded whitespace-nowrap ${
                  isAnnotationMode
                    ? "bg-gray-700 text-green-700"
                    : "hover:bg-gray-700"
                }`}
              >
                Annotate
              </button>

              {/* Measure Distance Mode Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDistanceMeasuringMode();
                }}
                className={`py-1 px-2 rounded whitespace-nowrap ${
                  isDistanceMeasuringMode
                    ? "bg-gray-700 text-green-700"
                    : "hover:bg-gray-700"
                }`}
              >
                Measure
              </button>

              {/* Scalpel Mode Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleScalpelMode();
                }}
                className={`py-1 px-2 rounded whitespace-nowrap ${
                  isScalpelMode
                    ? "bg-gray-700 text-green-700"
                    : "hover:bg-gray-700"
                }`}
              >
                Scalpel
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDrillMode();
                }}
                className={`py-1 px-2 rounded whitespace-nowrap ${
                  isDrillMode
                    ? "bg-gray-700 text-green-700"
                    : "hover:bg-gray-700"
                }`}
              >
                Drill
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCameraOrientationMode();
                }}
                className="py-1 px-2 hover:bg-gray-700 rounded whitespace-nowrap"
              >
                Camera Orientation
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSurgeryPosMode();
                }}
                className="py-1 px-2 hover:bg-gray-700 rounded whitespace-nowrap"
              >
                Surgery Position
              </button>
              {!isMobile && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFullScreen();
                  }}
                  className="py-1 px-2 hover:bg-gray-700 rounded whitespace-nowrap"
                >
                  {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </button>
              )}
            </div>

            {/* Message Boxes */}
            {/* TODO Change the names properly. */}
            {isDistanceMeasuringMode && (
              <DistanceMeasuringModal
                clearMeasurements={clearMeasurements}
                closePolygon={closePolygon}
                setIsMeasurementModalOpen={setIsMeasurementModalOpen}
                pathPoints={pathPoints}
              />
            )}

            {isScalpelMode && (
              <ScalpelModal
                isMobile={isMobile}
                showOptions={showOptions}
                setShowOptions={setShowOptions}
                cutHeight={cutHeight}
                setCutHeight={setCutHeight}
                modelRef={modelRef}
                selectedModels={selectedModels}
                setSelectedModels={setSelectedModels}
                setProcessing={setProcessing}
                modifyGeometryAndAddToScene={modifyGeometryAndAddToScene}
              />
            )}

            {isDrillMode && (
              <DrillModal brushSize={brushSize} setBrushSize={setBrushSize} />
            )}

            {/* Annotation Modal */}
            {isAnnotationModalOpen && (
              <div className="ui-outside-scene">
                <AnnotationModal
                  isOpen={isAnnotationModalOpen}
                  onSave={handleSaveAnnotation}
                  onCancel={handleCancelAnnotation}
                  initialPosition={tempAnnotationDetails}
                />
              </div>
            )}
            {/* Measurement Modal */}
            {isMeasurementModalOpen && (
              <div className="ui-outside-scene">
                <MeasurementModal
                  isOpen={isMeasurementModalOpen}
                  onSave={handleSaveMeasurements}
                  onCancel={() => setIsMeasurementModalOpen(false)}
                />
              </div>
            )}

            {/* Measurement Modal */}
            {isAddSurgeryPosModalOpen && (
              <div className="ui-outside-scene">
                <AddSurgeryPosModal
                  isOpen={isAddSurgeryPosModalOpen}
                  onSave={addSurgeryPos}
                  onCancel={() => setIsAddSurgeryPosModalOpen(false)}
                />
              </div>
            )}
            {/* Camera Orientation Modal */}
            {isCameraOrientationMode && (
              <CameraOrientationModal
                resetCamera={resetCamera}
                setHomeCameraView={setHomeCameraView}
                goHomeCameraView={goHomeCameraView}
                isHomeViewSet={Boolean(
                  homeOrientation && homeOrientation.position
                )}
                isCameraRotationLocked={isCameraRotationLocked}
                toggleCameraRotationLock={toggleCameraRotationLock}
                goTopView={goTopView}
                goBottomView={goBottomView}
                goLeftView={goLeftView}
                goRightView={goRightView}
                goFrontView={goFrontView}
                goBackView={goBackView}
              />
            )}
            {isSurgeryPosMode && (
              <SurgeryPositionModal
                goToSurgeryPosition={goToSurgeryPosition}
                saveSurgeryPosition={saveSurgeryPosition}
                addSurgeryPos={() => setIsAddSurgeryPosModalOpen(true)}
                deleteSurgeryPos={deleteSurgeryPos}
                setSelectedSurgeryPosId={setSelectedSurgeryPosId}
                selectedCase={thisCase}
                selectedSurgeryPosId={selectedSurgeryPosId}
                isPositionValid={true}
              />
            )}
            {processing && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="text-white text-lg font-bold">
                  Processing...
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelViewer;
